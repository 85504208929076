import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Cookies from "js-cookie"
import SideNavbar from './SideNavbar';
import Personaldetails from './Personaldetails';
import UserDashboard from './UserDashboard';
import Accountsetting from './Accountsetting'
export default function Dashboard() {
    const [sidenavbar, setSidenavbar] = useState(true);
    const [uppernavbar, setUppernavbar] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState('Personal Details');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [pincode, setpincode] = useState('');
    const [address, setaddress] = useState('');
    const [occupation, setoccupation] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [userdata, setUserdata] = useState({

    })

    const [fnameedit, setFnameedit] = useState(false);
    const [lnameedit, setLnameedit] = useState(false);


    useEffect(() => {
        // const data_to =Cookies.get("id");
        const data_to = { id: Cookies.get("id"), name: Cookies.get("name") };
        axios.post("https://ranilgroup.com/api/user_login_data.php", data_to).then((response) => {
            // console.log(response.data);
            const object = {
                email: response.data.email,
                phone: response.data.phone_number,
                fname: response.data.fname,
                lname: response.data.lname,
                pincode: response.data.pincode,
                address: response.data.address,
                occupation: response.data.occupation,
                state: response.data.state,
                city: response.data.city,
            }
            setUserdata(object);
            // console.log("The user data is ")
            // console.log(userdata);
            setFname(response.data.fname);
            setLname(response.data.lname);
        });
    }, []);

    return (
        <div className={uppernavbar?"":'d-flex'} style={{minHeight: "100vh"}}>
            <SideNavbar name={fname} activeNavItem={activeNavItem} setActiveNavItem={setActiveNavItem} sidenavbar={sidenavbar} setSidenavbar={setSidenavbar} uppernavbar={uppernavbar} setUppernavbar={setUppernavbar} />
            {
                activeNavItem === 'Personal Details'?<Personaldetails userdata={userdata} sidenavbar={sidenavbar} pincode={pincode} state={state} city={city} address={address} occupation={occupation} fname={fname} lname={lname} phone={phone} email={email} fnameedit={fnameedit} lnameedit={lnameedit} uppernavbar={uppernavbar} setUppernavbar={setUppernavbar} setFnameedit={setFnameedit} setLnameedit={setLnameedit} />:activeNavItem === 'Dashboard'?<UserDashboard sidenavbar={sidenavbar} uppernavbar={uppernavbar}/>:activeNavItem === 'Account Settings'?<Accountsetting sidenavbar={sidenavbar} name={fname} email={userdata.email} phone={userdata.phone} uppernavbar={uppernavbar}/>:""
            }


        </div>
    )
}
