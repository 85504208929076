import React, { useState, useEffect } from 'react'
import "../css/signup.css";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import "../css/otp.css";
export default function Signup() {
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState(true);
  const [inputs, setinputs] = useState({ username: '', email: '', password: '' });
  const [otpinputs, setOtpInputs] = useState({ digit1: "", digit2: "", digit3: "", digit4: "", digit5: "", digit6: "" });
  const navigate = useNavigate();
  const [otpdisplay, setOtpDisplay] = useState(false);

  const handlechange = (e) => {
    setinputs({ ...inputs, [e.target.name]: e.target.value });
  }
  const handlechange_otp = (e) => {
    setOtpInputs({ ...otpinputs, [e.target.name]: e.target.value });
  }

  const submit_cust = async (event) => {
    event.preventDefault();
    const data_to = { username: inputs.username, email: inputs.email, password: inputs.password }
    // await axios.post("https://ranilgroup.com/api/user_signup.php", data_to).then((response) => {
    // alert(response.data);

    //  navigate('/Login'); 
    if (inputs.username == "" || inputs.email === "" || inputs.password === "") {
      setAlert(true);
      setMessage("Please fill all the required field");
    } else { 
      setForm(false);
      setLoader(true);
      await axios.post("https://ranilgroup.com/api/user_signup.php", data_to).then((response) => {
        // console.log(response);
        if (response.data === "Account Already existed , try login  ") {
          setLoader(false);
          setForm(true);
          setAlert(true);
          setMessage("Account already exist please try Login");
        }
        else {
          setLoader(false);
          setOtpDisplay(true);
        }
      });
    }
    
  }

  const submit_verify = (event) => {
    event.preventDefault();
    const data_to = { d_1: otpinputs.digit1, d_2: otpinputs.digit2, d_3: otpinputs.digit3, d_4: otpinputs.digit4, d_5: otpinputs.digit5, d_6: otpinputs.digit6, username: inputs.username, email: inputs.email, password: inputs.password };
    axios.post("https://ranilgroup.com/api/otp_validate.php", data_to).then((response) => {
      // alert(response.data);
      setOtpDisplay(true);
      if (response.data.result === "Wrong Credentials") {
        setAlert(true);
        setMessage("Invalid credentials");
      }
      else if (response.data.result === "Account Created Successfully") {
        navigate('/Login');
      }
      else {
        setAlert(true);
        setMessage("Technical error");
      }

    });
  }


  const handleNextInput = (event) => {
    const current = event.target;
    const index = parseInt(current.classList[1].split('__')[2]);
    current.value = event.key;

    if (event.keyCode === 8 && index > 1) {
      current.previousElementSibling.focus();
    }
    if (index < 6 && '0123456789'.indexOf(event.key) !== -1) {
      const next = current.nextElementSibling;
      if (next) {
        next.focus();
      }
    }

    let finalKey = '';
    const otpInputs = document.querySelectorAll('.otp__digit');
    otpInputs.forEach(({ value }) => {
      finalKey += value;
    });

    // Use finalKey as needed
  };
  useEffect(() => {
    const otpInputs = document.querySelectorAll('.otp__digit');
    otpInputs.forEach((input) => {
      input.addEventListener('keyup', handleNextInput);
    });

    return () => {
      otpInputs.forEach((input) => {
        input.removeEventListener('keyup', handleNextInput);
      });
    };
  }, []);

  return (
    <body>
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>

      <div className='signup_div'>
        {loader ? <form className='signup_form' style={{ display: loader ? "flex" : "none", flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
          <p>We are sending an email to verify your account<br />
            <p className="loader"></p>
          </p>
        </form> :
          <form className='signup_form' style={{ display: form ? "flex" : "none", flexDirection: 'column' }}>
            <h2>Signup Here</h2>

            <label htmlFor="username">Name</label>
            <input name='username' value={inputs.username} onChange={handlechange} className='input_signup' type="text" placeholder="Name" id="username" required />
            <label htmlFor="email">Email</label>
            <input name='email' value={inputs.email} onChange={handlechange} className='input_signup' type="text" placeholder="Email" id="username" required />
            <label htmlFor="password">Password</label>
            <input name='password' value={inputs.password} onChange={handlechange} className='input_signup' type="password" placeholder="Password" id="password" required />
            <div className='already'>
              <p>Already have an account ? <Link to='/Login'>Login Now!</Link></p>
            </div>

            <button onClick={submit_cust} className='login_button text-center'>Signup</button>
            {alert ? <div class="alert fogetpass alert-danger" role="alert">
              {message}
            </div> : ""}
          </form>}

      </div>
      <form action="javascript: void(0)" style={{ display: otpdisplay ? "block" : "none" }} className="otp-form signup_form" name="otp-form">

        <div className="title">
          <h3>OTP VERIFICATION</h3>
          <p className="info">An otp has been sent to {inputs.email}</p>
          <p className="msg">Please enter OTP to verify</p>
        </div>
        <div className="otp-input-fields">
          <input type="number" name='digit1' onChange={handlechange_otp} className="otp__digit otp__field__1 otpinput" />
          <input type="number" name='digit2' onChange={handlechange_otp} className="otp__digit otp__field__2 otpinput" />
          <input type="number" name='digit3' onChange={handlechange_otp} className="otp__digit otp__field__3 otpinput" />
          <input type="number" name='digit4' onChange={handlechange_otp} className="otp__digit otp__field__4 otpinput" />
          <input type="number" name='digit5' onChange={handlechange_otp} className="otp__digit otp__field__5 otpinput" />
          <input type="number" name='digit6' onChange={handlechange_otp} className="otp__digit otp__field__6 otpinput" />
        </div>

        <div className="result"><button onClick={submit_verify} className="custom-gradient-button" style={{ padding: "5px 17px", width: "40%", border: "1px solid black" }}>Verify Now</button></div>
        {alert ? <div class="alert fogetpass alert-danger" role="alert">
          {message}
        </div> : ""}
      </form>


    </body>

  )
}
