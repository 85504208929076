import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Investors = () => {
  return (
    <div className='container my-4'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <h1 style={{ fontFamily: "Noto Serif JP, serif" }}>Land Investment with Ranil Group:<br /> A Path to Prosperity</h1>
          </div>
          <div className='row my-2'>
            <p>
              Ranil Group extends its legacy of excellence beyond property development into the realm of land investment, where fertile opportunities await discerning investors. With decades of experience and a proven track record, we offer a trusted platform for individuals and organizations seeking to maximize their investments in land.
            </p>
            <p>
              Our strategic approach to land investment involves meticulous research, comprehensive due diligence, and a keen understanding of market dynamics. We identify and acquire land parcels that hold immense potential for future growth and development. From prime urban spaces to promising suburban landscapes, we curate an extensive portfolio of land assets designed to yield substantial returns.
            </p>
          </div>
          <div className='row'>
            <div className='row text-center' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
              <h2>Top Investor</h2>
            </div>
            <div className='container d-flex my-3' style={{ overflowX: "auto", alignItems: "center" }}>
              <div className='col-md-3 col-6 d-flex justify-content-center align-items-center '>
                <center>
                  <div data-aos="zoom-in">
                    <div className='d-flex justify-content-center align-items-center mb-3' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                      <img src='./Image/ss group.png' style={{ width: "100%", height: "auto" }}></img>
                    </div>
                  </div>
                </center>

              </div>
              <div className='col-md-3 col-6 d-flex justify-content-center align-items-center '>
                <center>
                  <div data-aos="zoom-in">
                    <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                      <img src='./Image/Pyramid_logo.jpg' style={{ width: "100%", height: "auto" }}></img>
                    </div>
                  </div>
                </center>
              </div>
              <div className='col-md-3 col-6 d-flex justify-content-center align-items-center'>
                <center>
                  <div data-aos="zoom-in">
                    <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                      <img src='./Image/OrientCraft.png' style={{ width: "125%", height: "auto" }}></img>
                    </div>
                  </div>
                </center>
              </div>
              <div className='col-md-3 col-6 d-flex justify-content-center align-items-center'>
                <center>
                  <div data-aos="zoom-in">
                    <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                      <img src='./Image/DLF_logo.jpg' style={{ width: "100%", height: "auto" }}></img>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className='row my-2'>
            <p>
              One of the cornerstones of our success lies in our esteemed partnerships. Ranil Group is honored to collaborate with industry leaders like SS Group, DLF, Orient Craft, and many more. These partnerships are a testament to our commitment to excellence and our dedication to fostering a thriving investment ecosystem. Our association with these renowned entities amplifies the trust and confidence that investors place in us.
            </p>
            <p>
              At Ranil Group, we believe that land isn't just an investment; it's a canvas of possibilities. It's the foundation upon which dreams are built and futures are secured. Whether you seek to diversify your investment portfolio or embark on a journey of strategic land acquisition, we invite you to explore the boundless opportunities that await you in the world of land investment with Ranil Group. Join us in shaping the landscapes of tomorrow and securing a prosperous future.
            </p>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='container' style={{ borderRadius: "20px", boxShadow: "5px 5px 8px 1px grey", overflow: "hidden" }}>
            <div className='row'>
              <img src='./Image/land4.jpg' alt='ranil car showrooms' style={{ width: "100%", padding: "0px" }}></img>
            </div>
            <div className='row text-center my-1'>
              <h4>Land Investement</h4>
              <h5>Price starting from 30,000 onward</h5>
            </div>
            <div className='row text-center'>
              <p> <FontAwesomeIcon icon={faMapLocationDot} /> 66, Arjun Marg, Block E, DLF phase 1, Sector 26a, Gurugram, Haryana</p>
              <p><FontAwesomeIcon icon={faPhone} /> 9999183288</p>
              <p><FontAwesomeIcon icon={faEnvelope} /> support@ranilgroup.com</p>
            </div>
            <div className='row mb-2'>
              <center><a href='/Investement' target='_blank'><button className="custom-gradient-button" style={{ padding: "7px 17px", width: "40%", border: "1px solid black" }}>Invest Now</button></a></center>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investors
