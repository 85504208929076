import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Card = ({ imagesrc, price, description, name, button, navigation }) => {
    const navigate = useNavigate();
    return (
        <div className='row card_responsive'>
            <div className='col-md-6' style={{ display: "flex", justifyContent: "center" }}>
                <img src={imagesrc} className='img-fluid2' alt='image' style={{ width: "100%", objectFit: "cover" }}></img>
            </div>
            <div className='col-md-6'>
                <div className='row'>
                    <h4>{name}</h4>
                </div>
                <div className='row'>
                    <p>{description}</p>
                </div>
                <div className='row'>
                    <p>{price?'\u20B9 ' + price : ''}</p>
                </div>
                <div className='row' style={{marginBottom:"1rem", justifyContent:"center"}}>
                {
                    button?<Link to={navigation}><button className="custom-gradient-button" style={{padding:"3px 13px", width:"67%", border:"1px solid black"}}>{button}</button></Link>:""
                }
                </div>
            </div>
        </div>
    )
}

export default Card
