// UserContext.js
import React, { createContext, useReducer, useContext } from 'react';
const UserContext = createContext();
export const useUserContext = () => {
  return useContext(UserContext);
};
const initialState = {
  user: null,
  isLoggedIn: false,
};
const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: action.payload.user,
        isLoggedIn: true,
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};
export const UserProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={{ userState, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
