import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react'

const Pricing = ({ progress, setProgress }) => {
    const [forminput, setforminput] = useState({ price: '', type: '', description: '' });
    const { type } = useParams();
    const [requiredData, setRequiredData] = useState(false);
    const handleClick = () => {
        if (forminput.price !== '' && forminput.type !== '') {
            const to_data = { id: Cookies.get('id'), price: forminput.price, type: forminput.type, description: forminput.description };
            // console.log(to_data);
            axios.post("https://ranilgroup.com/api/pricing.php", to_data).then((response) => {
                // console.log(response);
                if (response.data.result === "Success") {
                    setProgress(progress + 1);
                }
            });
        } else {
            setRequiredData(true);
            // console.log("fill required data");
        }
    }
    const changehandle = (e) => {
        setforminput({ ...forminput, [e.target.name]: e.target.value });
    }
    return (
        <div className='container'>
            <div className='row my-3'>
                <h3 style={{ fontFamily: "Noto Serif JP, serif" }}> Add Pricing and details...</h3>
            </div>
            {
                requiredData ? <div className="alert alert-danger" role="alert">
                    Please fill all the required fields
                </div> : ""
            }
            <div className='row mt-2'>
                <h5>Price details </h5>
                {
                    type === "Property" || "Land" ?<input onChange={changehandle} type='number' name='price' className='form-control' placeholder='Your expected Price' style={{ width: "50%" }}></input>:<input onChange={changehandle} type='number' name='price' className='form-control' placeholder='Price per Month' style={{ width: "50%" }}></input>
                }
                <div className='row' onChange={changehandle}>
                    <div className='col-md-6'>
                        <div class="form-check">
                            <input class="form-check-input" name='type' type="radio" value="1" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                All inclusive tax
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div class="form-check">
                            <input class="form-check-input" name='type' type="radio" value="2" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                excluded tax charges
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div class="form-check">
                            <input class="form-check-input" name='type' type="radio" value="3" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                Price negotiable
                            </label>
                        </div>
                    </div>

                </div>
            </div>
            <div className='row mt-3'>
                {
                    type === "Property"?<h5>What makes your property unique</h5>:<h5>Write description of your land</h5>
                }
                <small>Adding description to your property increase its visibility</small>
                <textarea onChange={changehandle} class="form-control mt-2" name='description' rows="8" placeholder="Share some details about your property like spacious room, lavish balcony, mountain view etc."></textarea>
            </div>
            <div className='row mt-2'>
                <button className='custom-gradient-button my-2' style={{ padding: "7px 13px", width: "30%", border: "1px solid black" }} onClick={handleClick} >
                    Post Property
                </button>
            </div>
        </div>
    )
}

export default Pricing
