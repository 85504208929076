import React from 'react'
import { Link } from 'react-router-dom'

const Faq = () => {
    return (
        <div>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <strong>How do I list my property on Ranil Group?</strong>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <p>
                        To list your property on Ranil Group, create an account or log in. Then, follow the easy steps to fill in details about your property, including photos and descriptions. Our platform will guide you through the process, making it quick and straightforward.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <strong>Is posting property on Ranil group is FREE?</strong>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <p>
                        Yes, you can post or list your property on ranilgroup.com for FREE. There is no charge involved. All buyers/Sellers contact details will be shared with you for FREE. 
                        </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                           <strong>What is the process for buying a property through Ranil Group?</strong>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p>
                            To buy a property, simply browse our listings, filter results based on your preferences, and express interest in properties you like. Our platform provides tools to schedule viewings, communicate with sellers, and complete transactions securely.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <strong>Are the properties on Ranil Group verified and legitimate?</strong>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p>
                            Yes, we take property and seller verification seriously. Our platform ensures that all listings and sellers go through a thorough verification process to maintain the authenticity and legitimacy of properties.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <strong>Is my personal information secure on Ranil Group?</strong>
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p>
                            Absolutely, we take data privacy and security seriously. Your personal information is protected by robust security measures, and we adhere to stringent privacy policies to safeguard your data.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <p>Still Have some doubts Contact us we will clear your doubts just <a href="Contact">click here</a></p>
            </div>
        </div>
    )
}

export default Faq
