// import "rsuite/dist/rsuite.min.css"; 
import { 
    Progress, 
    ButtonGroup, 
    Button 
} from "rsuite"; 
import { useEffect, useState } from "react"; 
  
export default function CircularProgressBar({progress}) { 
    const [ percent, setPercent ] = useState(20); 
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
     
      async function fetchdata() {
       await setLoading(true);
        try {
          const temp = progress*20+20;
          await setPercent(temp);
          setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        } 
    }
    fetchdata();
    },[]);
    const status = 
        percent === 100 ? "success" : null; 
    const color = 
        percent === 100 ? "#03D613" : "#D65003"; 
  
    return ( 
        <div> 
            {loading ? // Display "Loading..." while data is loading
                    <p>Loading...</p>
                : 
            <div style={{ padding: 20, }}> 
                <div>
                
                    <div style={{ width: 120 }}> 
                        <Progress.Circle 
                            percent={progress*20+20} 
                            strokeColor={color} 
                            status={status} 
                        /> 
                    </div>

                </div> 
            </div> 
            } 
        </div> 
    ); 
}