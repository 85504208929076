import React, { useState } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Accountsetting = ({ name, email, phone, sidenavbar, uppernavbar }) => {
  const navigate = useNavigate();
  const [phone_no, setphone] = useState(false);
  const [Password, setPassword] = useState(false);
  const [forminput,setforminput] = useState({new_password:'',confirm_password:''});
  const handlechange = (e)=>{
    setforminput({...forminput,[e.target.name]:e.target.value});
  }
  const savepassword = ()=>{
    if(forminput.new_password==forminput.confirm_password){
    const data_to = {id:Cookies.get("id"),new_password:forminput.new_password,confirm_password:forminput.confirm_password};
    // console.log(data_to);
    axios.post("https://ranilgroup.com/api/update_details.php?account",data_to).then((response)=>{
        // console.log(response);
        if(response.data.result=="updated"){
          alert("password updated");
          window.location.reload(false);
        }
    });
    }else{
      // console.log("password not matched");
    }
  }

  const deleteaccount = ()=>{
    const data_to = {id:Cookies.get('id')};
    axios.post("https://ranilgroup.com/api/deactivate_account.php?",data_to).then((response)=>{
        // console.log(response);
        if(response.data.result=="deleted"){
          alert("Account Deactivated");
          Cookies.remove("id");
          Cookies.remove("name");
          navigate("/");
        }
    });
  }

  return (
    <div className={uppernavbar?"container my-5":sidenavbar?'container my-5 marginleftdashboard':'container my-5 marginleftdashboardresponsive'} style={{ paddingLeft:"5%", borderRadius:"25px", boxShadow:"6px 5px 7px 0px grey", width: uppernavbar?"95%":sidenavbar?"81%":"91%"}}>
      <h3 className='text-center'>Your account settings</h3>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <h5>Phone Number <small>(You can't Change your email)</small> </h5>
          
            <p>{phone}</p>
          
        </div>
        <div className='col-md-6'>
          <h5>Email adress <small>(You can't Change your email)</small></h5>
          <p>{email}</p>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-6'>
          <h5>Current Password</h5>
          <p>Password</p>
          <a href='#' onClick={()=>{setPassword(!Password)}}>Change Password?</a>
          {
            Password?<>
            <input name='new_password' onChange={handlechange} type='password' className='form-control my-2' placeholder='Enter New password' style={{width:"50%"}}></input><input name='confirm_password' onChange={handlechange} type='password' className='form-control mb-2' placeholder='Confirm New password' style={{width:"50%"}}></input>
            <button onClick={savepassword} className="custom-gradient-button mx-2" style={{ padding: "5px 13px", width: "25%", border: "1px solid black", marginTop: "20px" }}>Save changes</button> 
             </>:""
          }
        </div>
        <div className='col-md-6'>
          <h5>Deactivate Your account</h5>
          <button className="custom-gradient-button-card my-2" style={{ width:"40%", background: "linear-gradient(to right, #ea3009, #f3ba14)"}} onClick={deleteaccount}>Delete Now</button>
        </div>
      </div>  
    </div>
  )
}

export default Accountsetting
