import React from 'react'

const CheckBoxbutton2 = ({label, selectedButton2, onSelect2}) => {
    const isChecked = selectedButton2 === label;

    const handleButtonClick = (e) => {
        e.preventDefault();
        onSelect2(label);
    };


    const buttonClass = isChecked ? 'custom-gradient-button mx-2' : 'custom-gradient-button2 mx-2';

  return (
          <button
            className={buttonClass}
            onClick={handleButtonClick}
        >
            {label}
        </button>
    
  )
}

export default CheckBoxbutton2
