import React from 'react'

const Underconstruction = () => {
    return (
        <div className='container my-3'>
            
                <img src='./Image/underconstruction.jpg' alt='underconstruction' style={{width:"100%"}}></img>            
        </div>
    )
}

export default Underconstruction
