import React  from 'react'
import Herosection from './Herosection';
import Describe from './Describe';
import TopProject from './TopProject';
import PostProperty from './PostProperty';
import Topinvestment from './Topinvestment';
import Upcomingprojects from './Upcomingprojects';
import Subsidiaries from './Subsidiaries';
import Visitussection from './Visitussection';
import Oursubsidiaries from './Oursubsidiaries';
const Home = () => {

    return (
        <>
        <Herosection/>
        <Describe/>
        <TopProject/>
        <PostProperty/>
        <Topinvestment/>
        <Upcomingprojects/>
        <Oursubsidiaries/>
        <Visitussection/>
        </>
    )
}

export default Home
