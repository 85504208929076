import React, { useEffect, useState } from 'react'
import data from "../topinvestement.json";
import Card2 from './Card2';
import axios from 'axios';


const Investement = () => {

  const [property, setproperty] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        
        async function fetchdata() {
            try {
                const response = await axios.get("https://ranilgroup.com/api/get_properties.php?homepage");
                await Array.prototype.push.apply(property, response.data);
                // console.log(property);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Set loading state to false when data fetching is complete
                setLoading(false);
            }
        }
        fetchdata();
    }, []);

  return (
    <div className='container'>
      <div className='row text-center my-3'>
        <h2 style={{ fontFamily: "Noto Serif JP, serif" }}>Explore all Ranil Groups properties</h2>
      </div>
      <div className='row'>
        {loading ? ( // Display "Loading..." while data is loading
                    <p>Loading...</p>
                ) : (
                    property.length > 0 ? (
                        property.map((item, key) => (
                          <>
                            <div className='col-md-6 col-12' key={key}>
                                {/* Render your Card component with item data */}
                                <Card2 imgsrc={item.img_path} price={item.price} name={item.name} button="Enquire Now" Scheme={item.scheme} Location={item.location} City={item.city} State={item.state} Country={item.country} Size={item.size} Purpose={item.purpose} navigation={"/Investement"}/>
                            </div>
                            </>
                        ))
                    ) : (
                        <p>No data available.</p>
                    )
                )}
      </div>
    </div>
  )
}

export default Investement
