import React from 'react'

const Postedsuccessfully = () => {
    return (
        <div className='container'>
            <div className='row my-3'>
                <h3 style={{ fontFamily: "Noto Serif JP, serif" }}>Congratulation! your property will be listed shortly</h3>
                <small>Go to your dashboard to manage all your posting and new properties</small>
                <a href='/Dashboard'><button className='custom-gradient-button my-2' style={{ padding: "7px 13px", width: "30%", border: "1px solid black" }}>
                    Dashboard
                </button></a>
            </div>
        </div>
    )
}

export default Postedsuccessfully
