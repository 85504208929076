import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Ladyflorence = () => {
    const navigate = useNavigate();
    return (
        <div className='container my-4'>
            <div className='row'>
                <div className='col-md-8' >
                    <div className='row text-center'>
                        <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>LADY FLORENCE PUBLIC SCHOOL</h1>
                    </div>
                    <div className='row text-center'>
                        <p>
                            Lady Florence Public School, a distinguished co-educational senior secondary institution nestled in the heart of Gurgaon, Haryana, stands as a beacon of educational excellence. Established in 1999 and affiliated with the Central Board of Secondary Education (CBSE), Lady Florence School is proudly managed by the esteemed Sh. Dalchand Raghav Educational Society.
                        </p>
                        <p>
                            At Lady Florence, our commitment to nurturing young minds and fostering holistic development is unwavering. Our modern and state-of-the-art facilities are a testament to this dedication, featuring a sparkling swimming pool, a grand ballroom, technologically advanced smart classrooms, and a fully air-conditioned environment. We believe in making quality education accessible to all, and our nominal and affordable fee structure reflects this ethos.
                        </p>
                        <p>
                            Our legacy of educational brilliance spans decades, and our three branches in Gurgaon continue to shape the future leaders of tomorrow. Beyond academic excellence, we emphasize character development and instill in our students the values of respect, integrity, and responsibility. Lady Florence Public School is not just an institution; it's a nurturing home where young talents are honed, dreams are nurtured, and brilliance is cultivated.
                        </p>
                        <p>
                            Join us on this educational journey, where boundaries cease to exist, and excellence is not just a destination—it's a way of life. Discover the unparalleled educational experience that Lady Florence School offers, and let your child embark on a path of knowledge, growth, and success.
                        </p>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='container' style={{borderRadius:"20px", boxShadow:"5px 5px 8px 1px grey", overflow:"hidden"}}>
                    <div className='row'>
                    <img src='./Image/lady_florence.jpg' alt='ranil international' style={{width:"100%", padding:"0px"}}></img>
                    </div>
                    <div className='row text-center mt-1'>
                        <h4>Lady Florence Public School</h4>
                        <h5>Admin</h5>
                    </div>
                    <div className='row text-center'>
                        <p><FontAwesomeIcon icon={faMapLocationDot}/> Begumpur Khatola, Sec-74, NH-8.    Gurgaon -122001</p>
                        <p><FontAwesomeIcon icon={faPhone} /> 9999183288</p>
                        <p><FontAwesomeIcon icon={faEnvelope} /> ladyflorencepublicschool@yahoo.com</p>
                    </div>
                    <div className='row mb-2'>
                        <center><a href='https://ladyflorencepublicschool.com/' target='_blank'><button className="custom-gradient-button" style={{padding:"5px 17px", width:"40%", border:"1px solid black"}}>Visit Us</button></a></center>
                    </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Ladyflorence
