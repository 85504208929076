import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import "./App.css";
import Login from "./component/Login";
import Signup from "./component/Signup";
import ContactUs from "./component/ContactUs";
import LayoutwithNavbar from "./component/LayoutwithNavbar";
import Subsidiaries from "./component/Subsidiaries";
import Ladyflorence from "./component/Ladyflorence";
import Ranilinternational from "./component/Ranilinternational";
import Ranilshowroom from "./component/Ranilshowroom";
import Otpverify from "./component/Otpverify";
import Investors from "./component/Investors";
import Investement from "./component/Investement";
import Underconstruction from "./component/Underconstruction";
import Forgot from "./component/Forgot";
import Buyandsell from "./component/Buyandsell";
import Moredetails from "./component/Moredetails";
import Dashboard from "./component/Dashboard";
import Allproperties from "./component/Allproperties";
import Result from "./component/Result";


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LayoutwithNavbar><Home/></LayoutwithNavbar>}/>
        <Route path="/Contact" element={<LayoutwithNavbar><ContactUs/></LayoutwithNavbar>}/>
        <Route path="/Subsidiary" element={<LayoutwithNavbar><Subsidiaries/></LayoutwithNavbar>}/>
        <Route path="/Ladyflorence" element={<LayoutwithNavbar><Ladyflorence/></LayoutwithNavbar>}/>
        <Route path="/Ranilinternational" element={<LayoutwithNavbar><Ranilinternational/></LayoutwithNavbar>}/>
        {/* <Route path="/Ranilshowroom" element={<LayoutwithNavbar><Ranilshowroom/></LayoutwithNavbar>}/> */}
        <Route path="/Investor" element={<LayoutwithNavbar><Investors/></LayoutwithNavbar>}/>
        <Route path="/Investement" element={<LayoutwithNavbar><Investement/></LayoutwithNavbar>}/>
        <Route path="/underconstruction" element={<LayoutwithNavbar><Underconstruction/></LayoutwithNavbar>}/>
        <Route path="/Buyandsell" element={<LayoutwithNavbar><Buyandsell/></LayoutwithNavbar>}/>
        <Route path="/Moredetails/:type" element={<LayoutwithNavbar><Moredetails/></LayoutwithNavbar>}/>
        <Route path="/Result" element={<LayoutwithNavbar><Result/></LayoutwithNavbar>}/>
        <Route path="/Allproperties" element={<LayoutwithNavbar><Allproperties/></LayoutwithNavbar>}/>
        <Route path="/Login" element={<Login/>}/>
        {/* <Route path="/forgot" element={<Forgot/>}/> */}
        <Route path="/verify" element={<Otpverify/>}/>
        <Route path="/Dashboard" element={<Dashboard/>}/>
        <Route path="/Signup" element={<Signup/>}/>
        <Route path="/Forgot" element={<Forgot/>}/>
      </Routes>
    </div>
  );
}

export default App;
