import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

const Card2 = ({imgsrc, price, Scheme, Location, State, Country, City, name, button, Size, Purpose }) => {

const [loading, setLoading] = useState(false);
const [msg,setmsg] = useState('');
const [finputs,setfinputs] = useState({fname:"",lname:"",email:"",phnumber:"",schedule_date:"",schedule_time:"",comments:""});
const handlechange = (e) =>{
    setfinputs({...finputs,[e.target.name]:e.target.value});
}
const submit_form = async (e) =>{
    // // console.log("hello");
    const data_form = {fname:finputs.fname,lname:finputs.lname,phnumber:finputs.phnumber,email:finputs.email,schedule_date:finputs.schedule_date,schedule_time:finputs.schedule_time,comments:finputs.comments}
    setLoading(true);
    await axios.post("https://ranilgroup.com/api/investors.php",data_form).then((response)=>{
        // // console.log(response); 
        setmsg(response.data.result);
        setLoading(false);
    });
}

    return (
        <>
            <div className='row card2_responsive'>
                <div className='col-md-6' style={{ display: "flex", justifyContent: "center" }}>
                    <img src={imgsrc} className='img-fluid2' alt='image' style={{ width: "100%", objectFit: "cover" }}></img>
                </div>
                <div className='col-md-6'>
                    <div className='row mt-1'>
                        <h4>{name}</h4>
                    </div>
                    <div className='row'>
                        <p><span>{Scheme ? "Scheme: " : ""}</span>{Scheme}</p>
                        <p>Available: {Size}</p>
                        <p>Purpose: {Purpose}</p>
                        <p><FontAwesomeIcon icon={faMapLocationDot} /> {Location} {City} {State} {Country}</p>


                    </div>
                    <div className='row'>
                        <p>&#8377;{price}</p>
                    </div>
                    <div className='row' style={{ marginBottom: "1rem", justifyContent: "center" }}>
                        <button className="custom-gradient-button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ padding: "3px 13px", width: "67%", border: "1px solid black" }}>{button}</button>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            {loading ? ( // Display "Loading..." while data is loading
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                                Enquiry for Investement</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        
                            <div className='container'>
                                <div className='row text-center'>
                                <center><img src='./Image/logo.png' style={{width:"20%"}}></img></center>
                                <p>Thankyou for showing Your Interest , we will get back to you</p>
                                </div>
                            </div>
                        </div>    
                    </div>
                    </div>
                ) :
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                                Enquiry for Investement</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        
                            <div className='container'>
                                <div className='row text-center'>
                                <center><img src='./Image/logo.png' style={{width:"20%"}}></img></center>
                                <p>At Ranil Group, we're delighted to assist you in your journey toward prosperous land investments. Please fill out the following information, and our expert team will get in touch with you to explore the exciting opportunities available. Your dream investment may be just a few steps away!</p>
                                </div>
                                
                                <div className='row mt-2'>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="text" onChange={handlechange} name='fname' class="form-control contact_input" placeholder="First Name" aria-label="Username" aria-describedby="basic-addon1" required />
                                    </div>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="text" onChange={handlechange} name='lname' class="form-control contact_input" placeholder="Last Name" aria-label="Username" aria-describedby="basic-addon1" required />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="email" onChange={handlechange} name='email' class="form-control contact_input" placeholder="Enter your email" aria-label="Username" aria-describedby="basic-addon1" required />
                                    </div>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="number" onChange={handlechange} name='phnumber' class="form-control contact_input" placeholder="Phone number" aria-label="Username" aria-describedby="basic-addon1" required/>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="date" onChange={handlechange} name='schedule_date' class="form-control contact_input" placeholder="date of meet" aria-label="Username" aria-describedby="basic-addon1" required/>
                                    </div>
                                    <div className='col-md-6 contact_margin'>
                                        <input type="Timing" onChange={handlechange} name='schedule_time' class="form-control contact_input" placeholder="Timimg of meet you want" aria-label="Username" aria-describedby="basic-addon1" required />
                                    </div>
                                </div>
                                <div className='row mt-2 p-2 '>
                                    <textarea class="form-control" onChange={handlechange} placeholder="Addition information you want to share" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                </div>
                            </div>
                        </div>
    
                        <div className="modal-footer">
                            <button onClick={submit_form} className="custom-gradient-button" style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Submit</button>
                        </div>

                    </div>

                </div>
}
            </div >

        </>
    )
}

export default Card2
