import React from 'react'

const Describe = () => {
    return (
        <div className='container my-3'>
            <div className='row row_2'>
                <div className='col-md-4'>
                    <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img src='./Image/about_us.png' className='img-fluid' alt='Image' style={{width:"100%"}}></img>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='row text-center' style={{ fontFamily: "Noto Serif JP, serif", fontWeight:"600" }}>
                        <h2>Who We Are</h2>
                    </div>
                    <div className='row text-center' style={{fontFamily: "Playfair Display, serif", fontSize:"small"}}>
                        <p>
                        Ranil Group is one of the most reputed and trusted group located in Gurgaon, India. The work of Ranil Group is not confined in a box. The prime business of Ranil group is real estate. It works in various verticals like construction, Education, Hospitals etc.
                        </p>
                        <p>
                        The company commenced its operations in India in 1994 and is into Real Estate development with a pan India presence and operations spanning all key segments of the Indian Real Estate industry. The company's operations encompass various aspects of real estate development such as identification and acquisition, project planning, designing, marketing and execution
                        </p>
                        <p className='hidden_para'>
                        Ranil Group is Real Estate and Development Company, a multi faced business, encompassing activities that range from the purchase of raw land and sale of improvised parcels to others. We are the developers that coordinate the activities and convert ideas on the paper into real property.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Describe
