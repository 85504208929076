import React, { useState, useEffect } from 'react'
import Checkboxbutton from './Checkboxbutton'
import CheckBoxbutton2 from './CheckBoxbutton2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Otpverify from './Otpverify';

const Postform = () => {
    const [selectedButton, setSelectedButton] = useState('');
    const [showotp, setshowOtp] = useState(false);
    const [alert, setAlert] = useState(false);
    const [phone, setPhone] = useState('');
    const [type, settype] = useState();
    const [input, setinput] = useState({ type: '', looking: '', contact: '' });
    const handleButtonSelect = (label) => {
        setSelectedButton(label);
        // console.log(label);
        setinput({ ...input, ["looking"]: label });
    };
    const [selectedButton2, setSelectedButton2] = useState('');
    const handleButtonSelect2 = (label) => {
        setSelectedButton2(label);
        setinput({ ...input, ["type"]: label });
    };
    const handlechange = (e) => {
        setinput({ ...input, [e.target.name]: e.target.value });
        // console.log(input);
    }
    const navigate = useNavigate();
    const submit_data = (e) => {
        e.preventDefault();
        // console.log(input);
        if (input.contact !== '' && input.type !== '') {
            const data_to = { id: Cookies.get('id'), type: input.type, looking: input.looking ? input.looking : "Sell", contact: input.contact };
            axios.post("https://ranilgroup.com/api/phone_otp.php", data_to).then((response) => {
                // console.log(response);
                if (response.data.result === "updated") {
                    alert("Verified");
                }
            });

        } else {
            // console.log("fill the form");
        }
        if (input.contact.length < 10) {
            alert("Enter correct phone number");
        }
        else {

            setshowOtp(true);
        }
        // console.log(showotp);
    }

    const handleSubmit = ()=>{
        if(phone != '' && selectedButton != '' && selectedButton2 != ''){
            navigate(`/Moredetails/${selectedButton}`)
        }
        else{
            setAlert(true);
        }
    }

    useEffect(() => {
        const data_to = { id: Cookies.get("id"), name: Cookies.get("name") };
        axios.post("https://ranilgroup.com/api/user_login_data.php", data_to).then((response) => {
            // console.log("The user data is ", response.data);
            setPhone(response.data.phone_number);
        });
    }, []);

    return (
        <div style={{ borderRadius: "20px", boxShadow: "5px 5px 8px 1px grey", overflow: "hidden", padding: "20px" }}>
            <form >
                {
                    alert?<div className="alert alert-danger" role="alert">
                    Please select the required field!
                  </div>:""
                }
                <div className="mb-3">
                    <h4>Start Posting Its FREE!</h4>
                    <small>Add Basic details</small>
                    <h5>You are looking to Sell?</h5>
                    <Checkboxbutton label="Property" name="type" value="Property" selectedButton={selectedButton} onSelect={handleButtonSelect} />
                    <Checkboxbutton label="Land" name="type" value="Land" selectedButton={selectedButton} onSelect={handleButtonSelect} />
                    <Checkboxbutton label="Rent" name="type" value="Land" selectedButton={selectedButton} onSelect={handleButtonSelect} />
                </div>
                <div className="mb-3">
                    <h5>And You are a..</h5>
                    <CheckBoxbutton2 label="Owner" selectedButton2={selectedButton2} onSelect2={handleButtonSelect2} />
                    <CheckBoxbutton2 label="Agent" selectedButton2={selectedButton2} onSelect2={handleButtonSelect2} />
                </div>
                <div className="mb-3">
                    <h5>Your Contact Details</h5>
                    {
                        phone ? <>{phone}</> : <input onChange={handlechange} name='contact' type="text" className="form-control" id="Phone_Number" />
                    }
                </div>
                <div className="mb-3 form-check" style={{ paddingLeft: "0px" }}>
                    {
                        phone?"":showotp ? <Otpverify data={input} setshowOtp = {setshowOtp}/> :
                        <a onClick={submit_data} href='#' >
                            Verify Now
                        </a>
                    }
                </div>
            </form>
            <button className='custom-gradient-button' onClick={() => { handleSubmit() }} style={{ padding: "5px 13px", width: "30%", border: "1px solid black" }}>
                Start Now
            </button>
        </div>
    )
}

export default Postform
