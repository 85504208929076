import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useData } from '../context/dataContext';
import { event } from 'jquery';

const Herosection = () => {
    const {data, setData} = useData();
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init();
    });
    const [searchvalue,setsearchvalue] = useState('');
    const handlchange = (e)=>{
        setsearchvalue(e.target.value);
    }

    const search = ()=>{
        const data_to = {search_value:searchvalue};
        // console.log(data_to);
        axios.post("https://ranilgroup.com/api/searched_property.php",data_to).then((response)=>{
                // console.log(response);
                if(response.data.result){
                // // console.log(response.data);
                const city = response.data.result;
                // console.log("This is a city", city);
                setData(city);
                // // console.log("this is context data", data);
                navigate(`/Result`);
                }else{
                    // console.log("error");
                }
        });
    }
  return (
    <div className='container-lg'>
            <div className='row'>
                <div className='col-md-6 col-12'>
                    <div className='row my-4'>
                        <div className='col-md-2'></div>
                        <div className='col-md-8' style={{ borderBottom: "2px solid #00abc3", fontFamily: "Lora, serif" }}>
                            <center><h5>Ranil Group Real estate co.</h5></center>
                        </div>
                        <div className='col-md-2' ></div>
                    </div>
                    <div className='row my-4 text-center'>
                        <div data-aos="fade-right" data-aos-offset="300" data-aos-duration="2000" data-aos-easing="ease-in-sine">
                            <h1 className='text-xl' style={{ fontFamily: "Noto Serif JP, serif" }}>
                            Your Search Your Way <br/> Let's Find Your Happy Place.
                            </h1>
                        </div>

                    </div>
                    <div className='row'>
                        <div className="bar" style={{ width: "60%" }}>
                            <input onChange={handlchange} className="searchbar" type="text" title="Search" placeholder='Enter the locality' style={{ width: "90%" }}
                            onKeyDown={(e)=>{
                                if(e.key == "Enter"){
                                    search();
                                }
                            }} />
                            <a href="#" onClick={search}><FontAwesomeIcon icon={faSearch} /></a>
                        </div>
                        <div className='p-2 w-100 my-3'>
                        {/* ()=>{navigate('/underconstruction')} */}
                            <center><button className="custom-gradient-button custom-gradient-button_2" onClick={search}>SEARCH</button></center>
                            
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <img src='./Image/Hero_section.png' className='img-fluid' alt='image'></img>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Herosection
