import React from 'react'

const Visitussection = () => {
  return (
    <div className='container'>
      <div className='row'>
        <h2 style={{ fontFamily: "Noto Serif JP, serif" }}>Visit Us</h2>
      </div>
      <div className='row my-3'>
        <p className='my-2' style={{fontFamily: "Playfair Display, serif", fontSize:"medium"}}>
        Embark on a Journey to Your Dream Property with Ranil Groups! Explore, Discover, and Let Us Guide You to Your Perfect Piece of Real Estate. Visit Us Today and Take the First Step Towards Your Ideal Home or Investment.
        </p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.454350381947!2d77.0998620754947!3d28.465860575756118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d2d4c5106f%3A0xa458924adbbe4700!2s66%2C%20Arjun%20Marg%2C%20Block%20E%2C%20DLF%20Phase%201%2C%20Sector%2026A%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1696533367988!5m2!1sen!2sin" title='Adress' width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Visitussection
