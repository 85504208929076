import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';

const ContactUs = () => {
    const [contact_input, setcontact_input] = useState({ fname: "", lname: "", phnumber: "", email: "", comments: "" });
    const [form, setForm] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const handlechange = (event) => {
        setcontact_input({ ...contact_input, [event.target.name]: event.target.value });
    }
    const submitcontactform = async () => {
        setLoading(true);
        setForm(false);
        const data_form = { fname: contact_input.fname, lname: contact_input.lname, phnumber: contact_input.phnumber, email: contact_input.email, comments: contact_input.comments }
        await axios.post("https://ranilgroup.com/api/contact_us.php", data_form).then((response) => {
            // console.log(response);
            setcontact_input({
                fname: "", lname: "", phnumber: "", email: "", comments: ""
            })
            if (response) {
                setSubmitted(true);
                setLoading(false);
            }
        })
    }
    return (
        <div className='container contact_form1 contact_form'>
            <div className='container' style={{ boxShadow: "8px 6px 10px 0px grey", borderRadius: "20px", backgroundColor: "whitesmoke" }}>
                <div className='row'>
                    <div className='col-md-4' style={{ padding: "20px" }}>
                        <div className='row text-center'>
                            <img src='./Image/logo.png' alt='logo' className='img-fluid contact_image' ></img>
                        </div>
                        <div className='row mt-2'>
                            <h5 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>Contact Information</h5>
                        </div>
                        <div className='row hidden_para'>
                            <p>
                                66, Arjun Marg, Block E, DLF phase 1, Sector 26a, Gurugram, Haryana
                            </p>
                        </div>
                        <div className='row'>
                            <p><span>Call Us: </span> 0124-4055078</p>
                        </div>
                        <div className='row'>
                            <p><span>Email Us: </span> <a href='#'>arungarg@ranilgroup.com</a></p>
                        </div>
                        <div className='row hidden_para'>
                            <p>WE'RE ACCOUNTABLE FOR SEVEN DAYS A WEEK, 24 HOURS A DAY</p>
                        </div>
                        <div className='row hidden_para'>
                            <h5 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>Follow Us on</h5>
                            <a className="fa "><FontAwesomeIcon icon={faFacebook} /></a>
                            <a className="fa "><FontAwesomeIcon icon={faTwitter} /></a>
                            <a className="fa "><FontAwesomeIcon icon={faInstagram} /></a>
                            <a className="fa"><FontAwesomeIcon icon={faLinkedin} /></a>
                        </div>
                    </div>
                    {loading ? <div className='col-md-8' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <p>
                            <h2>We are sending a mail to the admin <br/>Thankyou for your patience!</h2>
                            <br />
                            <center><p className="loader"></p></center>
                        </p>
                    </div> : ""}

                    <div className='col-md-8' style={{ display: submitted ? "flex" : "none", alignItems: "center", justifyContent: "center" }} >
                        <div className='row p-3'>
                            <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}> Thankyou for Contacting Us!</h1>
                            <p>We will get back to you soon...</p>
                        </div>
                    </div>
                    <div className='col-md-8 contact_form2 contact_form' style={{ display: form ? "block" : "none", margin: "auto" }}>
                        <div className='row my-3'>
                            <div className='col-md-6 contact_margin'>
                                <input type="text" className="form-control contact_input" name='fname' onChange={handlechange} placeholder="First Name" aria-label="Username" aria-describedby="basic-addon1" required/>
                            </div>
                            <div className='col-md-6'>
                                <input type="text" className="form-control contact_input" name='lname' onChange={handlechange} placeholder="Last Name" aria-label="Username" aria-describedby="basic-addon1" required/>
                            </div>
                        </div>
                        <div className='row my-3'>
                            <div className='col-md-6 contact_margin'>
                                <input type="number" className="form-control contact_input" id="exampleFormControlInput1" name='phnumber' onChange={handlechange} placeholder="Phone number" required/>
                            </div>
                            <div className='col-md-6'>
                                <input type="email" className="form-control contact_input" id="exampleFormControlInput1" name='email' onChange={handlechange} placeholder="Enter your email" required/>
                            </div>
                        </div>
                        <div className='row my-3 p-2'>
                            <textarea className="form-control" name='comments' onChange={handlechange} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                        </div>
                        <div className='row p-2 text-center'>
                            <center><button className="custom-gradient-button" onClick={submitcontactform} style={{ padding: "5px 17px", width: "20%", border: "1px solid black" }}>Submit</button></center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
