import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import Cookies from "js-cookie"
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from 'react-router-dom';



const SideNavbar = ({ name, activeNavItem, setActiveNavItem, sidenavbar, setSidenavbar, uppernavbar, setUppernavbar }) => {
    const navigate = useNavigate();
    const handleNavItemClick = (item) => {
        setActiveNavItem(item);
    }

    useEffect(() => {
        const handleMediaQuery = (mediaQueryList) => {
            if (mediaQueryList.matches) {
                setUppernavbar(true);
                // console.log('Mobile screen detected');
            } else {
                setUppernavbar(false);
                // console.log('Not a mobile screen');
            }
        }

        const mediaQueryList = window.matchMedia('(max-width: 767px)');

        mediaQueryList.addListener(handleMediaQuery);

        handleMediaQuery(mediaQueryList);

        return () => {
            mediaQueryList.removeListener(handleMediaQuery);
        };
    }, [])

    const signout = ()=>{
        Cookies.remove("id");
        Cookies.remove("name");
        navigate("/");
    }

    return (
        <>
            {
                uppernavbar ?
                    <nav class="navbar bg-dark border-bottom border-body" data-bs-theme="dark">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">Ranil Group</a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className={activeNavItem === 'Personal Details'?"nav-link active":"nav-link"} href="#" onClick={() => handleNavItemClick('Personal Details')}>Personal Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={activeNavItem === 'Dashboard'?"nav-link active":"nav-link"} href="#" onClick={() => handleNavItemClick('Dashboard')}>Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={activeNavItem === 'Account Settings'?"nav-link active":"nav-link"} href="#" onClick={() => handleNavItemClick('Account Settings')}>Account setting</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={activeNavItem === 'Account Settings'?"nav-link active":"nav-link"} href="/" onClick={() => handleNavItemClick('Home')}>Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={signout} href="#">Sign Out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    :
                    <div className=" flex-column flex-shrink-0 p-3 text-bg-dark position-fixed" style={{ width: sidenavbar ? "225px" : "80px", minHeight: "100vh", display: "flex" }}>
                        {
                            sidenavbar ? <h5 style={{display:"flex"}} >Welcome {name} <FontAwesomeIcon icon={faBars} style={{ color: "#618ad1", marginLeft: "38px" }} size='xl' onClick={() => setSidenavbar(!sidenavbar)} /></h5> : <center><FontAwesomeIcon icon={faBars} style={{ color: "#618ad1" }} size='xl' onClick={() => setSidenavbar(!sidenavbar)} /></center>
                        }
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto">
                            <li style={{ color: "white" }}>
                                {
                                    sidenavbar ? <a href="#" className={`nav-link ${activeNavItem === 'Personal Details' ? 'active' : ''}`} onClick={() => handleNavItemClick('Personal Details')}>
                                        <FontAwesomeIcon icon={faHouseUser} style={{ color: "#fcfcfc", marginRight: "5px" }} />
                                        Personal Details
                                    </a> : <a href="#" style={{ width: "fit-content" }} className={`nav-link ${activeNavItem === 'Personal Details' ? 'active' : ''}`} onClick={() => handleNavItemClick('Personal Details')}>
                                        <center><FontAwesomeIcon icon={faHouseUser} style={{ color: "#fcfcfc" }} /></center>
                                    </a>
                                }
                            </li>
                            <li style={{ color: "white" }}>
                                {
                                    sidenavbar ? <a href="#" className={`nav-link ${activeNavItem === 'Dashboard' ? 'active' : ''}`} onClick={() => handleNavItemClick('Dashboard')}>
                                        <FontAwesomeIcon icon={faChartLine} style={{ color: "#f4f5f5", marginRight: "5px" }} />
                                        Dashboard
                                    </a> : <a href="#" style={{ width: "fit-content" }} className={`nav-link ${activeNavItem === 'Dashboard' ? 'active' : ''}`} onClick={() => handleNavItemClick('Dashboard')}>
                                        <center><FontAwesomeIcon icon={faChartLine} style={{ color: "#f4f5f5" }} /></center>
                                    </a>
                                }
                            </li>
                            <li style={{ color: "white" }}>
                                {
                                    sidenavbar ? <a href="#" className={`nav-link  ${activeNavItem === 'Account Settings' ? 'active' : ''}`} onClick={() => handleNavItemClick('Account Settings')}>
                                        <FontAwesomeIcon icon={faGear} style={{ color: "#f7f7f8", marginRight: "5px" }} />
                                        Account Settings
                                    </a> : <a href="#" style={{ width: "fit-content" }} className={`nav-link  ${activeNavItem === 'Account Settings' ? 'active' : ''}`} onClick={() => handleNavItemClick('Account Settings')}>
                                        <center><FontAwesomeIcon icon={faGear} style={{ color: "#f7f7f8" }} /></center>
                                    </a>
                                }
                            </li>
                            <li style={{ color: "white" }}>
                                {
                                    sidenavbar ? <a href="/" className={`nav-link  ${activeNavItem === 'Home' ? 'active' : ''}`} onClick={() => handleNavItemClick('Home')}>
                                        <FontAwesomeIcon icon={faHouseUser} style={{ color: "#fcfcfc", marginRight: "5px" }} />
                                        Home
                                    </a> : <a href="/" style={{ width: "fit-content" }} className={`nav-link  ${activeNavItem === 'Home' ? 'active' : ''}`} onClick={() => handleNavItemClick('Home')}>
                                        <center><FontAwesomeIcon icon={faHouseUser} style={{ color: "#fcfcfc", marginRight: "5px" }} /></center>
                                    </a>
                                }
                            </li>
                        </ul>
                        <hr />
                        <div className="dropdown">
                            {
                                sidenavbar ? <>
                                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        {Cookies.get('name') ? <span className="user_login" style={{ background: "white", color: "black", border: "1px solid black", marginRight: "1.5rem", width: "30px" }}><a href="#">{Cookies.get('name')[0].toUpperCase()}</a></span> :
                                            <a href="/Signup"><span style={{ marginLeft: "10px" }} ><FontAwesomeIcon icon={faUser} /></span></a>
                                        }
                                        <strong>{name}</strong>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                                        <li><a className="dropdown-item" href="/Buyandsell">New Posting..</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={signout} href="#">Sign out</a></li>
                                    </ul></> : <>
                                    <a href="#" className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        {Cookies.get('name') ? <span className="user_login" style={{ background: "white", color: "black", border: "1px solid black", width: "30px", left: "0px" }}><a href="#">{Cookies.get('name')[0].toUpperCase()}</a></span> : ""
                                        }
                                    </a>
                                    <ul className="dropdown-menu text-small shadow" style={{}}>
                                        <li><a className="dropdown-item" href="/Buyandsell">New Posting..</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={signout} href="#">Sign out</a></li>
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
            }


        </>
    );
}

export default SideNavbar;
