import React, { useEffect, useState } from 'react'
import DashboardCard from './DashboardCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import RecentlyConnectedcard from './RecentlyConnectedcard'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import axios from 'axios'
import Cookies from 'js-cookie'


const UserDashboard = ({ sidenavbar, uppernavbar }) => {
  const [selectedCard, setselectedCard] = useState({});
  const [property, setproperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [click, setClick] = useState(0);
  const [propertyWithMaxClicks, setPropertyWithMaxClicks] = useState({});
  const [input, setinput] = useState({ basic_id: '', city: '', state: '', address: '', price: '', description: '' });
  const handleInputChange = (e) => {
    setinput({ ...input, [e.target.name]: e.target.value });
    // console.log(selectedCard);
  };
  // console.log(property);
  // console.log(propertyWithMaxClicks);

  const update_data = () => {
    var data_to = {};
    if (selectedCard.type == "land") {
      data_to = { type: "land", basic_id: selectedCard.basic_details_id, city: input.city ? input.city : selectedCard.city, state: input.state ? input.state : selectedCard.state, address: input.address ? input.address : selectedCard.address, price: input.price ? input.price : selectedCard.price, description: input.description ? input.description : selectedCard.description };
    } else {
      data_to = { type: "house", basic_id: selectedCard.basic_details_id, city: input.city ? input.city : selectedCard.city, state: input.state ? input.state : selectedCard.state, address: input.address ? input.address : selectedCard.address, price: input.price ? input.price : selectedCard.price, description: input.description ? input.description : selectedCard.description };
    }
    // console.log(data_to);
    axios.post("https://ranilgroup.com/api/edit_properties.php", data_to).then((response) => {
      // console.log(response);
      if (response.data.result === "updated") {
        alert("data updated");
        window.location.reload(false);
      }
    });
  }

  const deletepost = () => {
    var data_to = {};
    if (selectedCard.type == "land") {
      data_to = { type: "land", basic_id: selectedCard.basic_details_id };
    } else {
      data_to = { type: "house", basic_id: selectedCard.basic_details_id };
    }
    // console.log(data_to);
    axios.post("https://ranilgroup.com/api/delete_properties.php", data_to).then((response) => {
      // console.log(response);
      if (response.data.result === "deleted") {
        alert("data deleted");
        window.location.reload(false);
      }
    });
  }



  useEffect(() => {
    async function fetchdata() {
      const data_to = { id: Cookies.get('id'), name: Cookies.get("name") };
      try {
        const response = await axios.post("https://ranilgroup.com/api/user_dashboard.php", data_to);
        await Array.prototype.push.apply(property, response.data);
        property.map((item) => {
          setClick(item.clicks + click);
        })
        if (property.length > 0) {
          let maxClicks = -1;

          property.forEach((item) => {
            if (item.clicks > maxClicks) {
              maxClicks = item.clicks;
              setPropertyWithMaxClicks(item);
            }
          });

          // console.log(propertyWithMaxClicks);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchdata();
  }, []);

  const state = {
    labels: ['Week1', 'Week2', 'Week3', 'Week4'],
    datasets: [
      {
        label: 'No of clicks per week',
        data: [65, 59, 80, 81]
      }
    ]
  }
  return (
    <div className={uppernavbar ? "container my-2" : sidenavbar ? 'container my-5 marginleftdashboard' : 'container my-5 marginleftdashboardresponsive'}>
      <h3 className='text-center' style={{ fontFamily: "Noto Serif JP, serif" }}>Welcome to your Personal Dashboard !</h3>
      <div className='row'>
        <span><h5 className='my-3'><strong>Your recent Post</strong>
        </h5></span>
        {
          property.length === 0 ? <p>Sorry but you haven't posted anything yet</p> : property.map((item, index) => (
            <div className='col-md-4'>
              <DashboardCard imagesrc={`https://ranilgroup.com/Upload_pic/${item.image_name}`} basic_details_id={item.basic_details_id} name={item.city} type={item.land ? "land" : "house"} electricity={item.electricity}
                water={item.water} address={item.address} state={item.state} city={item.city} rooms={item.rooms} floors={item.floors} price={item.price} description={item.description} button="Edit property" setselectedCard={setselectedCard} style={{ width: "300px", height: "280px" }} />
            </div>
          ))

        }

      </div>

      <div className='row'>
        <h5 className='my-3'><strong>Your best performing property</strong></h5>
        {
          property.length === 0 ? <p>Sorry You haven't posted anything yet <br /> <a href='/Buyandsell'>Start Posting Now</a> </p> : <div className='col-md-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <DashboardCard imagesrc={`https://ranilgroup.com/Upload_pic/${propertyWithMaxClicks.image_name}`} name={propertyWithMaxClicks.city} basic_details_id={propertyWithMaxClicks.basic_details_id} type={propertyWithMaxClicks.land ? "land" : "house"} electricity={propertyWithMaxClicks.electricity}
              water={propertyWithMaxClicks.water} address={propertyWithMaxClicks.address} state={propertyWithMaxClicks.state} city={propertyWithMaxClicks.city} rooms={propertyWithMaxClicks.rooms} floors={propertyWithMaxClicks.floors} price={propertyWithMaxClicks.price} description={propertyWithMaxClicks.description} button="Edit property" />
          </div>
        }

      </div>
      <div className='row my-2'>
        {
          property.length === 0 ? <h5 style={{ textAlign: "center", fontFamily: "Noto Serif JP, serif", fontWeight: "500", borderRadius: "20px", color: "black", background: "#89a4a5", padding: "16px" }}>Post your first property on Ranil group.com to get genuine leads Hurry up its FREE <FontAwesomeIcon icon={faHandshake} /></h5> : <h5 style={{ textAlign: "center", fontFamily: "Noto Serif JP, serif", fontWeight: "500", borderRadius: "20px", color: "black", background: "#89a4a5", padding: "16px" }}>Hurray you have got {click} total numbers of clicks till Now! Keep going and keep posting on Ranilgroup.com <FontAwesomeIcon icon={faHandshake} /></h5>
        }
      </div>
      <div className='row'>
        <h5 className='my-3'><strong>Your clicks performance</strong></h5>
        {
          property.length === 0 ? <p>Post Your first property to get details analysis of your property ads only on Ranilgroup.com <br /> <a href='/Buyandsell'><button className="custom-gradient-button-responsive mx-2 my-2" style={{ width: "20%" }}>Sell Now</button></a></p> : <div style={{ width: "100%" }}>
            <Line data={state} />
          </div>
        }
      </div>
      <div className='row my-2'>
        <h5>Have some doubts in your mind? Feel free to contact Us </h5>
        <a href='/Contact'><button className="custom-gradient-button-responsive mx-2" style={{ marginTop: "20px" }} >Contact Us</button></a>
      </div>

      {/* MOdal start here  */}
      {
        selectedCard ? <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                  Edit Details</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">

                <div className='container'>
                  <div className='row mt-2'>
                    <div className='col-md-6 contact_margin'>
                      <input type="text" name='city' class="form-control contact_input" placeholder={selectedCard.city} onChange={handleInputChange} aria-label="city" aria-describedby="basic-addon1" required />
                    </div>
                    <div className='col-md-6 contact_margin'>
                      <input type="text" name='state' class="form-control contact_input"
                        placeholder={selectedCard.state} onChange={handleInputChange} aria-describedby="basic-addon1" required />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-md-6 contact_margin'>
                      <input type="text" name='address' class="form-control contact_input" placeholder={selectedCard.address} onChange={handleInputChange} aria-describedby="basic-addon1" required />
                    </div>
                    <div className='col-md-6 contact_margin'>
                      <input type="text" name='price' class="form-control contact_input" placeholder={selectedCard.price} onChange={handleInputChange} aria-describedby="basic-addon1" required />
                    </div>
                  </div>

                  <div className='row mt-2 p-2 '>
                    <textarea class="form-control" name='description' placeholder={selectedCard.description} onChange={handleInputChange} id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                  </div>
                  <div className='row mt-2'>
                    <h5><strong>Property Got Sold Out?</strong></h5>
                    <small>Delete it to protect yourself from getting spam!</small>
                    <button className="custom-gradient-button mt-2 mx-2" style={{ padding: "7px 17px", width: "30%", background: "linear-gradient(to right, #f34507, #ccbd00)", border: "1px solid black" }} onClick={deletepost}>Delete Now</button>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button onClick={update_data} className="custom-gradient-button" style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Submit</button>
              </div>
            </div>
          </div>
        </div> : ""
      }
      {/* Modal end here  */}
    </div>
  )
}

export default UserDashboard
