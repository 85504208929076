import React from 'react'
import { useNavigate } from 'react-router-dom'

const PostProperty = () => {
    const navigate = useNavigate();

    
    return (

        <div className='container my-3'>
            <div className='row' style={{ backgroundColor: "#b1adad", borderRadius: "50px" }}>
                <div className='col-md-8'>
                    <div className='row  text-center mt-2'>
                        <h2 style={{ fontFamily: "Dancing Script, cursive" }}>Post your Property for FREE</h2>
                    </div>
                    <div className='row text-center'>
                        <p style={{ fontFamily: "Cormorant, serif" }}>List on Ranil group and get Genuine Leads</p>
                    </div>
                </div>
                <div className='col-md-4 d-flex justify-content-center align-items-center'>
                <button className="custom-gradient-button my-2" onClick={()=>{navigate('/Buyandsell')}}>Buy/Sell Properties for free</button>
                </div>
            </div>
            <div className='row'>
                <div className='row text-center my-3' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
                    <h2>Top Investor</h2>
                </div>
                <div className='container d-flex my-3' style={{ overflowX: "auto", alignItems: "center" }}>
                    <div className='col-md-3 col-6 d-flex justify-content-center align-items-center '>
                        <center>
                            <div data-aos="zoom-in">
                                <div className='d-flex justify-content-center align-items-center mb-2' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                                    <img src='./Image/ss group.png' style={{ width: "100%", height: "auto" }}></img>
                                </div>
                            </div>
                        </center>

                    </div>
                    <div className='col-md-3 col-6 d-flex justify-content-center align-items-center '>
                    <center>
                            <div data-aos="zoom-in">
                                <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                                    <img src='./Image/Pyramid_logo.jpg' style={{ width: "100%", height: "auto" }}></img>
                                </div>
                            </div>
                        </center>
                    </div>
                    <div className='col-md-3 col-6 d-flex justify-content-center align-items-center'>
                    <center>
                            <div data-aos="zoom-in">
                                <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                                    <img src='./Image/OrientCraft.png' style={{ width: "125%", height: "auto" }}></img>
                                </div>
                            </div>
                        </center>
                    </div>
                    <div className='col-md-3 col-6 d-flex justify-content-center align-items-center'>
                    <center>
                            <div data-aos="zoom-in">
                                <div className='d-flex justify-content-center align-items-center' style={{ border: "2px solid black", borderRadius: "120px", width: "55%", overflow: "hidden", boxShadow: "5px 3px 7px 0px grey" }}>
                                    <img src='./Image/DLF_logo.jpg' style={{ width: "100%", height: "auto" }}></img>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PostProperty
