import React, { useState } from 'react'
import "../css/login.css";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
// import { CookiesProvider, useCookies } from "react-cookie";
import Cookies from 'js-cookie';

export default function Login() {
  const [inputs, setinputs] = useState({ username: '', password: '' });
  const [alert, setAlert] = useState(false);
  const { userState, dispatch } = useUserContext();
  const [forgot, setforgot] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  
  const handlechange = (e) => {
    setinputs({ ...inputs, [e.target.name]: e.target.value });
  }
  const submit_form = async (event) => {
    event.preventDefault();
    // // console.log(inputs);
    const data_to = { username: inputs.username, password: inputs.password };
    await axios.post("https://ranilgroup.com/api/user_login.php", data_to).then((response) => {
      
      if (response.data.result === "invalid credentials pls try again") {
        setAlert(true);
        setMessage("Invalid credentials");
      }
      else if (response.data.result === "Account doesn't exist") {
        setAlert(true);
        setMessage("Account doesn't exist");
      }
      else {
        const user = response.data;
        dispatch({ type: 'LOGIN', payload: { user } });
        // console.log(user);
        Cookies.set('name',user.result[0].fname, { expires: 7 });
        Cookies.set('id',user.result[0].id, { expires: 7 });
        navigate('/');
      }
    });
  }
  return (
    <body>
      <div class="background">
        <div class="shape"></div>
        <div class="shape"></div>
      </div>

      <form className='login_form'>
        <h2>Login Here</h2>
        {/* {alert?<div class="alert alert-danger" role="alert">
          {message}
        </div>:""} */}
        <label htmlFor="username">Username</label>
        <input onChange={handlechange} className='input_login' name='username' type="text" placeholder="Email" id="username" />
        <label htmlFor="password">Password</label>
        <input onChange={handlechange} className='input_login' name='password' type="password" placeholder="Password" id="password" />
        <div className='already'>
          <p><Link to="/forgot">Forgot password</Link></p>
        </div>
        {alert?<div class="alert fogetpass alert-danger" role="alert">
                {message}
                </div>:""}
        <button onClick={submit_form} className='login_button'>login</button>
        
      </form>
    </body>

  )
}
