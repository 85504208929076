import React, { useEffect } from 'react'
import Howitworks from './Howitworks'
import Postform from './Postform'
import Faq from './Faq'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

const Buyandsell = () => {
  const navigate = useNavigate();
  if (!Cookies.get('name')) {
    // console.log("Ye user logged in nahi hai ");

    return (
      <>
        <div className='container my-3'>
          <div className='row'>
            <h2 style={{ fontFamily: "Noto Serif JP, serif" }}>Unlock the Power to Sell Your Property with RanilGroup.com!</h2>
          </div>
          <div className='row'>
            <p>Are you ready to make your property stand out in the competitive real estate market? At Ranil Group, we've created a seamless and efficient platform that empowers you to sell your property quickly and effectively. To get started, all you need to do is create a Ranil Group account. Here's why you should take that first step:</p>
          </div>
          <div className='row'>
            <h3 style={{ fontFamily: "Noto Serif JP, serif" }}>Why Sign Up to Sell with Ranil Group:</h3>
          </div>
          <div className='row'>
            <p><strong>Maximize Exposure:</strong> Your property deserves the spotlight it needs to find the perfect buyer. By signing up, you'll gain access to premium features that increase your property's visibility in our listings.</p>
            <p><strong>Create Listings with Ease:</strong> Our user-friendly platform makes it simple to create detailed property listings with high-quality images and compelling descriptions. Showcase your property in the best possible light.</p>
            <p><strong>Targeted Marketing:</strong> Our platform offers targeted marketing tools that help your property reach the right audience. Use advanced filters to connect with potential buyers who are genuinely interested in your property's features.</p>
            <p><strong>Manage Inquiries:</strong> Handling inquiries from potential buyers has never been more straightforward. With your account, you can efficiently respond to inquiries and schedule viewings, streamlining the sales process.</p>
            <p><strong> Expert Support:</strong> Our team of experienced professionals is here to assist you at every stage. Whether you need advice on pricing, marketing, or negotiations, we're just a message away.</p>
            <p><strong>Real-time Updates:</strong> Stay informed about the status of your property listings. Receive real-time updates on views, inquiries, and potential offers through your Ranil Group dashboard.</p>
          </div>
          <div className='row'>
            <p>
              <strong>How to Sign Up:</strong> <br/>
              Creating your Ranil Group account is quick and easy. Click on the "Sign Up" or "Register" button, follow a few simple steps, and you'll be ready to start showcasing your property to a wide audience.
            </p>
          </div>
          <div className='row'>
            <p>
              <strong>Join Ranil Group Today:</strong> <br/>
              Take the first step towards a successful property sale. Sign up today, create your property listings, and get ready to connect with potential buyers. Ranil Group is your partner in making your property selling experience hassle-free and rewarding.
            </p>
          </div>


          <center><button className="custom-gradient-button" onClick={() => { navigate('/Signup'); }} style={{ padding: "7px 13px", width: "30%", border: "1px solid black" }}>Sign Up Now</button></center>
        </div>
      </>
    )

  }

  return (
    <>
      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="./Image/modern_home_sale.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="./Image/luxury_home_for_sale.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="./Image/lands_for_sale.jpg" className="d-block w-100" alt="..." />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className='container my-3'>
        <div className='row text-center my-3'>
          <h2 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "500" }}>Post Your property Ad for Free! Exclusively on Ranil Group</h2>
        </div>
        <div className='row'>
          <div className='col-md-7'>
            <Howitworks />
          </div>
          <div className='col-md-5'>
            <Postform />
          </div>
        </div>
        <div className='row mt-3'>
          <h2 className='text-center mb-2' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "500" }}>Benefits of selling properties at Ranilgroup</h2>
          <div className='col-md-6'>
            <img src='./Image/convenience.png' alt='Convenience'></img>
            <h5><strong>Convenience:</strong></h5>
            <p>Selling properties online through Ranil Group offers unmatched convenience. Sellers can list their properties, upload all relevant information, and manage the entire sales process from the comfort of their homes. This eliminates the need for time-consuming in-person meetings and paperwork, making the process efficient and hassle-free.</p>
          </div>
          <div className='col-md-6'>
            <img src='./Image/wider_reach.png' alt='Wider_reach' style={{ width: "30%" }}></img>
            <h5><strong>Wider Reach:</strong></h5>
            <p>
              Online property listings have a global reach, allowing sellers to showcase their properties to a much broader audience. Ranil Group's online platform ensures that potential buyers from different locations can easily discover and express interest in listed properties, increasing the chances of a quicker sale.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-12'>
            <img src='./Image/cost_effective.png' alt='cost_effective' style={{ width: "25%" }}></img>
            <h5><strong>Cost-Effective:</strong></h5>
            <p>
              Selling properties online can be more cost-effective than traditional methods. There are typically lower fees associated with online listings and transactions. Additionally, sellers can save on expenses related to physical marketing materials and open houses, leading to cost savings in the overall sales process.
            </p>
          </div>
          <div className='col-md-6 col-12'>
            <img src='./Image/transparency.png' alt='Transparency and information' style={{ width: "24%" }}></img>
            <h5><strong>Transparency and Information:</strong></h5>
            <p>
              Ranil Group's online platform offers transparency through verified seller and property profiles, detailed property history, and user-generated reviews. Buyers have access to a wealth of information, helping them make informed decisions. This transparency builds trust and can lead to quicker and smoother property transactions.
            </p>
          </div>

        </div>
        <div className='row'>
          <small className='text-center mt-2'>Know more about posting on Ranilgroup.com</small>
          <h2 className='text-center my-2'>Frequently Asked question</h2>
          <Faq />
        </div>
      </div>
    </>
  )
}

export default Buyandsell
