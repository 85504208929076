import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Ranilinternational = () => {
    return (
        <div className='container my-4'>
            <div className='row'>
                <div className='col-md-7'>
                    <div className='row text-center'>
                        <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>RANIL INTERNATIONAL SCHOOL</h1>
                    </div>
                    <div className='row text-center'>
                        <p>
                            Explore Ranil International School, a beacon of educational excellence established in 2016, nestled in the vibrant city of Badhshahpur, Gurugram, Haryana. Our institution is dedicated to providing top-tier education, catering to students from nursery to grade 3rd, all under the esteemed guidance of the Central Board of Secondary Education (CBSE).
                        </p>
                        <p>
                            Our modern campus boasts well-equipped classrooms, a richly stocked library, a cutting-edge computer lab, and an expansive playground—creating the perfect environment for nurturing well-rounded individuals. However, what truly sets us apart is our passionate and dedicated team of educators who go above and beyond. They emphasize not only academic achievement but also character development.
                        </p>
                        <p>
                            At Ranil International School, education transcends textbooks. We offer a diverse array of extracurricular activities, including dance, music, and sports, ensuring your child's holistic growth. Our strong focus on values such as respect, integrity, and responsibility prepares students for a world beyond the classroom.
                        </p>

                        <p>
                            Join us in shaping the future leaders of tomorrow. Ranil International School is not just an educational institution; it's a nurturing home where young talents flourish, dreams take flight, and excellence is the norm. Discover the Ranil difference and give your child the gift of a brighter tomorrow.
                        </p>

                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='container' style={{borderRadius:"20px", boxShadow:"5px 5px 8px 1px grey", overflow:"hidden"}}>
                    <div className='row'>
                    <img src='./Image/ranil_international.png' alt='ranil international' style={{width:"100%", padding:"0px"}}></img>
                    </div>
                    <div className='row text-center mt-1'>
                        <h4>Ranil International School</h4>
                        <h5>Admin</h5>
                    </div>
                    <div className='row text-center'>
                        <p><FontAwesomeIcon icon={faMapLocationDot}/> F257+PX7, Street Number 10, Phase 2, Madanpuri, Sector 8, Gurugram, Haryana 122001</p>
                        <p><FontAwesomeIcon icon={faPhone} /> 9836272352</p>
                        <p><FontAwesomeIcon icon={faEnvelope} /> support@ranil_international.com</p>
                    </div>
                    <div className='row mb-2'>
                        <center><button className="custom-gradient-button" style={{padding:"5px 17px", width:"20%", border:"1px solid black"}}>Visit Us</button></center>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ranilinternational
