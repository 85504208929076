import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import "../css/PropertyProfile.css"
import Cookies from "js-cookie"
import axios from 'axios';

const PropertyProfile = ({ progress, setProgress }) => {
    const [alert, setAlert] = useState(false);
    const [frominput, setforminput] = useState({});
    const { type } = useParams();
    const handleClick = () => {
        if (type === "Property" || type === "Rent") {
            if (frominput.rooms !== '' && frominput.bathroom !== '' && frominput.balcony !== '' && frominput.type !== '' && frominput.area !== '' && frominput.floor !== '' && frominput.status !== '') {
                // setAlert(true);
                var rent_or_sell = "Rent";
                if(type=== "Property"){
                    rent_or_sell = "Sell";
                }
                const data_to = { type_identity:1,type_rent_or_sell:rent_or_sell, id: Cookies.get('id'), rooms: frominput.rooms, bathroom: frominput.bathroom, balcony: frominput.balcony, type: frominput.type, area: frominput.area, floor: frominput.floor, status: frominput.status };
                // console.log("hello");
                // console.log(data_to);
                axios.post("https://ranilgroup.com/api/propertyprofile.php", data_to).then((response) => {
                    // console.log(response);
                    if (response.data.result === "Success") {
                        setProgress(progress + 1);
                    }
                });
            }else{
                setAlert(true);
            }
        }
        else if(type === 'Land'){
            if ( frominput.typeofland !== '' && frominput.area !== ''  && frominput.status !== '') {
                // setAlert(true);
                const data_to = {type_identity:0, id: Cookies.get('id'),electricity:frominput.electricity?frominput.electricity:0, land_type: frominput.typeofland, area: frominput.area, status: frominput.status , water:frominput.water?frominput.water:0,road:frominput.road?frominput.road:0 };
                // console.log(data_to);
                axios.post("https://ranilgroup.com/api/propertyprofile.php", data_to).then((response) => {
                    // console.log(response);
                    if (response.data.result === "Success") {
                        setProgress(progress + 1);
                    }
                });
            }
            else{
                setAlert(true);
            }
        }
    }
    const handlechange = (e) => {
        setforminput({ ...frominput, [e.target.name]: e.target.value });
        // console.log(frominput);
    }
    return (
        <div className='container '>
            <div className='row my-3'>
                <h3 style={{ fontFamily: "Noto Serif JP, serif" }}>Tell Us about your property</h3>
                {alert ? <div className="alert alert-danger" role="alert">
                    Please fill all the required fields
                </div> : ""}
            </div>
            <div className='row mt-2'>
                {
                    type === "Property"  || type === "Rent" ? <>
                        <h6>Add Room details</h6>
                        <div class="main-container">
                            <div class="radio-buttons" name="rooms" onChange={handlechange} style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="rooms" />
                                    <span class="radio-btn">
                                        1
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="rooms" />
                                    <span class="radio-btn">
                                        2
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="3" type="radio" name="rooms" />
                                    <span class="radio-btn">
                                        3
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="4" type="radio" name="rooms" />
                                    <span class="radio-btn">
                                        4
                                    </span>
                                </label>
                            </div>
                        </div></> : <>
                        <h6>Type of Land</h6>
                        <div class="main-container">
                            <div class="radio-buttons" name="typeofland" onChange={handlechange} style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="Residential" type="radio" name="typeofland" />
                                    <span class="radio-btn" style={{ width: "100px" }}>
                                        Residential
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="Commercial" type="radio" name="typeofland" />
                                    <span class="radio-btn" style={{ width: "100px" }}>
                                        Commercial
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="Agriculture" type="radio" name="typeofland" />
                                    <span class="radio-btn" style={{ width: "100px" }}>
                                        Agriculture
                                    </span>
                                </label>
                            </div>
                        </div></>
                }
            </div>
            <div className='row mt-2'>
                {
                    type === "Property" || type === "Rent" ? <>
                        <h6>No of bathrooms</h6>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="bathroom" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="bathroom" />
                                    <span class="radio-btn">
                                        1
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="bathroom" />
                                    <span class="radio-btn">
                                        2
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="3" type="radio" name="bathroom" />
                                    <span class="radio-btn">
                                        3
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="4" type="radio" name="bathroom" />
                                    <span class="radio-btn">
                                        4
                                    </span>
                                </label>
                            </div>
                        </div></> : <>
                        <h6>Amenities Provided</h6>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="amenities" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="checkbox" name="water" />
                                    <span class="radio-btn" style={{ width: "100px", height: "52px" }}>
                                        Water Supply
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="1" type="checkbox" name="electricity" />
                                    <span class="radio-btn" style={{ width: "100px", height: "52px" }}>
                                        Electricity Supply
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="1" type="checkbox" name="road" />
                                    <span class="radio-btn" style={{ width: "100px", height: "52px" }}>
                                        Road Connection
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="1" type="checkbox" name="other" />
                                    <span class="radio-btn" style={{ width: "100px", height: "52px" }}>
                                        Others
                                    </span>
                                </label>
                            </div>
                        </div></>
                }
            </div>
            <div className='row mt-2'>
                {
                    type === "Property"  || type === "Rent" ? <>
                        <h6>No of Balconies</h6>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="balcony" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="balcony" />
                                    <span class="radio-btn">
                                        1
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="balcony" />
                                    <span class="radio-btn">
                                        2
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="3" type="radio" name="balcony" />
                                    <span class="radio-btn">
                                        3
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="4" type="radio" name="balcony" />
                                    <span class="radio-btn">
                                        4
                                    </span>
                                </label>
                            </div>
                        </div></> : ""
                }
            </div>
            <div className='row mt-2'>
                <h5>Add area details</h5><small>In sq.ft.</small>
                <input type='number' onChange={handlechange} name='area' className="form-control" placeholder='Plot Area' style={{ width: "45%" }}></input>
            </div>
            <div className='row mt-2'>
                {
                    type === "Property"  || type === "Rent" ? <>
                        <h5>Furnishing</h5>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="type" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="type" />
                                    <span class="radio-btn" style={{ width: "135px" }}>
                                        Semi Furnished
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="type" />
                                    <span class="radio-btn" style={{ width: "135px" }}>
                                        Fully Furnished
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="3" type="radio" name="type" />
                                    <span class="radio-btn" style={{ width: "135px" }}>
                                        Un-Furnished
                                    </span>
                                </label>
                            </div>
                        </div></> : ""
                }
            </div>
            <div className='row mt-2'>
                {type === "Property"  || type === "Rent" ? <>
                    <h5>Floor details</h5>
                    <input type='Number' onChange={handlechange} name='floor' className="form-control" placeholder='Total Floors' style={{ width: "45%" }}></input></> : ""}
            </div>
            <div className='row mt-2'>
                {
                    type === "Property"  || type === "Rent" ? <>
                        <h5>Availability Status</h5>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="status" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="status" />
                                    <span class="radio-btn" style={{ width: "135px" }}>
                                        Ready to move
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="status" />
                                    <span class="radio-btn" style={{ width: "150px" }}>
                                        Under Construction
                                    </span>
                                </label>
                            </div>
                        </div></> : <>
                        <h5>Availability Status</h5>
                        <div class="main-container">
                            <div class="radio-buttons" onChange={handlechange} name="status" style={{ display: "flex" }}>
                                <label class="custom-radio">
                                    <input value="1" type="radio" name="status" />
                                    <span class="radio-btn" style={{ width: "135px" }}>
                                        Ready to move
                                    </span>
                                </label>
                                <label class="custom-radio">
                                    <input value="2" type="radio" name="status" />
                                    <span class="radio-btn" style={{ width: "170px" }}>
                                        Under Consideration
                                    </span>
                                </label>
                            </div>
                        </div></>
                }
            </div>
            <div className='row'>
                <button className='custom-gradient-button my-2' style={{ padding: "5px 13px", width: "30%", border: "1px solid black" }} onClick={handleClick} >
                    Continue
                </button>
            </div>

        </div>
    )
}

export default PropertyProfile
