import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'


const Otpverify = ({text, header,data}) => {
    useEffect(() => {
        var otp_inputs = document.querySelectorAll(".otp__digit")
        var mykey = "0123456789".split("")
        otp_inputs.forEach((_) => {
            _.addEventListener("keyup", handle_next_input)
        })
        function handle_next_input(event) {
            let current = event.target
            let index = parseInt(current.classList[1].split("__")[2])
            current.value = event.key

            if (event.keyCode == 8 && index > 1) {
                current.previousElementSibling.focus()
            }
            if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
                var next = current.nextElementSibling;
                next.focus()
            }
            var _finalKey = ""
            for (let { value } of otp_inputs) {
                _finalKey += value
            }
        }
    });
    const[ showotp, setShowOtp] = useState(true);
    const [otpinput,setotpinput] = useState({num1:'',num2:'',num3:'',num4:'',num5:'',num6:''});
    const handlechange = (e)=>{
        setotpinput({...otpinput,[e.target.name]:e.target.value});
    }
    const submitotp = ()=>{
        const data_to = {num1:otpinput.num1,num2:otpinput.num2,num3:otpinput.num3,num4:otpinput.num4,num5:otpinput.num5,num6:otpinput.num6,id:Cookies.get('id'),type:data.type,looking:data.looking?data.looking:"Sell",contact:data.contact};
            axios.post("https://ranilgroup.com/api/postform.php",data_to).then((response)=>{
                // console.log(response);
                if(response.data.result==="updated"){
                    alert("Verified");
                    setShowOtp(false);
                }
            });
    }


    return (
        <div>
            {
                showotp?<>
                <div className='container'>
            <div className='row'>
                <form action="javascript: void(0)" class="otp-form" name="otp-form">

                    <div class="title">
                        <h3>{header}</h3>
                        <p class="info">{text}</p>
                        <p class="msg">enter OTP to verify</p>
                    </div>
                    <div class="otp-input-fields">
                        <input onChange={handlechange} name='num1' type="number" class="otp__digit otp__field__1 otpinput" />
                        <input onChange={handlechange} name='num2' type="number" class="otp__digit otp__field__2 otpinput" />
                        <input onChange={handlechange} name='num3' type="number" class="otp__digit otp__field__3 otpinput" />
                        <input onChange={handlechange} name='num4' type="number" class="otp__digit otp__field__4 otpinput" />
                        <input onChange={handlechange} name='num5' type="number" class="otp__digit otp__field__5 otpinput" />
                        <input onChange={handlechange} name='num6' type="number" class="otp__digit otp__field__6 otpinput" />
                    </div>

                    <div class="result"><button onClick={submitotp} className="custom-gradient-button" style={{ padding: "5px 17px", width: "25%", border: "1px solid black" }}>Validate </button></div>
                </form>
            </div>
        </div></>:""
            }
        </div>
    )
}

export default Otpverify
