import React, {useState} from 'react'

const Checkboxbutton = ({ label, selectedButton, onSelect }) => {
    const isChecked = selectedButton === label;
    const handleButtonClick = (e) => {
        e.preventDefault();
        onSelect(label);
    };


    const buttonClass = isChecked ? 'custom-gradient-button mx-2' : 'custom-gradient-button2 mx-2';

    return (
        <button
            className={buttonClass}
            onClick={handleButtonClick}
        >
            {label}
        </button>
    )
}

export default Checkboxbutton
