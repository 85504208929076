import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useData } from '../context/dataContext'
import axios from 'axios';
import Cookies from 'js-cookie';
import Allpropertycard from './Allpropertycard';

const Result = () => {
    const [selectedProperty, setSelectedProperty] = useState({});
    const { data, setData } = useData();
    const [showmodal, setShowmodal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const [loader, setLoader] = useState(false);

    const contact = () => {
        setLoader(true);
        const data_to = { id: Cookies.get('id'), to: selectedProperty.email, name: Cookies.get('name') };
        // // console.log(data_to);
        axios.post("https://ranilgroup.com/api/contact_property.php", data_to).then((response) => {
            // console.log(response);
            setLoader(false);
            if (response.data.result === "Thankyou for contacting us , we will get back to you") {
                alert(" Email Sent to respected owner");
            } else {
                // console.log("error");
            }

        });
    }
    useEffect(() => {
        if (selectedProperty) {
            const imagesForProperty = data
                .filter(item => item.id === selectedProperty.id)
                .map(item => item.image_name);

            setCarouselImages(imagesForProperty);
            console.log("Hello Ji kya haal hai");
            setShowmodal(true);
        } else {
            console.log("NHI AAYA ABHI TAK CLICK");
        }
    }, [selectedProperty]);
    return (
        <div className='container my-3'>
            <div className='row'>
                {
                    data.length > 0 ?
                        data.map((item, index) => (
                            <>
                                <div className='col-md-4 mt-3' key={index}>
                                    <Allpropertycard city={item.city} image_name={item.image_name} address={item.address} phone={item.phone_number} state={item.state} description={item.description} price={item.price} item={item} setSelectedProperty={setSelectedProperty} />
                                </div>
                            </>
                        )) : <p>Sorry! No data available.</p>
                }
            </div>




            {/* modal for knowmore about property  */}
            {
                showmodal ? <div className="modal fade" id="knowmoremodal" tabIndex="-1" aria-labelledby="knowmoremodalLabel" aria-hidden="true">
                    {loader ? <form className='signup_form' style={{ display: loader ? "flex" : "none", flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
                        <p>We are sending an email to Owner with your contact details <br />
                            <p className="loader"></p>
                        </p>
                    </form> :
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                                        {selectedProperty.city} {selectedProperty.state}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='container'>
                                        <div className='row'>
                                            {/* <img src={`https://ranilgroup.com/Upload_pic/${selectedProperty.image_name}`} alt='property image' style={{ objectFit: "cover", height: "300px" }}></img> */}
                                            <div  id="carouselExample" class="carousel slide">
                                                    
                                                    <div className="carousel-inner">
                                                        {
                                                            carouselImages.map((item, key) => (
                                                                
                                                                <>
                                                                    <div key={key} className={`carousel-item ${key === 0?"active":""}`}>
                                                                        <img src={`https://ranilgroup.com/Upload_pic/${item}`} alt="..." style={{ objectFit: "cover", height: "300px" }} />
                                                                    </div>
                                                                </>
                                                            ))
                                                        }

                                                    </div>

                                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Previous</span>
                                                    </button>
                                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Next</span>
                                                    </button>
                                                </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6 col-6'>
                                                {
                                                    selectedProperty.land ? <h6><strong>Total Area: </strong> {selectedProperty.area}<small>Sq.ft</small></h6> : <h6><strong>Carpet Area: </strong> {selectedProperty.area}<small>Sq.ft</small></h6>
                                                }
                                            </div>
                                            <div className='col-md-6 col-6'>
                                                {
                                                    selectedProperty.land ? "" : <h6><strong>Configuration: </strong> {selectedProperty.rooms} Bedrooms, {selectedProperty.bathroom} Bathrooms, {selectedProperty.balconies} Balconies </h6>
                                                }
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6 col-6'>
                                                {
                                                    selectedProperty.land ? <h6><strong>Property Status: </strong> {selectedProperty.status === 1 ? "Ready to move" : "Pending"}</h6> : <h6><strong>Property Status: </strong> {selectedProperty.status === 1 ? "Ready to move" : "Under Construction"}</h6>
                                                }
                                            </div>
                                            <div className='col-md-6 col-6'>
                                                {
                                                    selectedProperty.land ? "" : <h6><strong>Furnishing: </strong> {selectedProperty.type === 1 ? "Semi Furnished" : selectedProperty.type === 2 ? "Fully Furnished" : "UnFurnished"}</h6>
                                                }
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6 col-6'>
                                                <h6><FontAwesomeIcon icon={faLocationDot} style={{ marginRight: "10px" }} /><strong>Address: </strong></h6>
                                                <p>{selectedProperty.address} {selectedProperty.city} {selectedProperty.state} {selectedProperty.pincode}</p>
                                            </div>
                                            <div className='col-md-6 col-6'>
                                                {
                                                    selectedProperty.land ? "" : <h6><strong>No of Floors: </strong> {selectedProperty.floors}</h6>
                                                }
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <h6><FontAwesomeIcon icon={faTag} style={{ color: "#1c5ac4", marginRight: "10px" }} /><strong>Price: </strong>Only at  {'\u20B9 ' + selectedProperty.price}</h6>
                                        </div>
                                        <div className='row mt-2'>
                                            <h6><strong>Description:</strong></h6>
                                            <p>{selectedProperty.description}</p>
                                        </div>
                                        <div className='row mt-2'>
                                            <h5><strong>Click on Contact Now to contact with owner</strong></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {
                                        Cookies.get('name') ? <button className="custom-gradient-button" onClick={contact} style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Contact Now</button> : <p>Please login to contact owner</p>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div > : ""
            }

        </div>
    )
}

export default Result
