import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faLocationDot, faRug } from '@fortawesome/free-solid-svg-icons';
import "../css/Allproperties.css";
import axios from 'axios';
import Allpropertycard from './Allpropertycard';
import Cookies from 'js-cookie';
export default function Allproperties() {
    const [selectedProperty, setSelectedProperty] = useState({});
    const [property, setproperty] = useState([]);
    const [showmodal, setShowmodal] = useState(false);
    const [data, setData] = useState([]);
    const [tempproperty, setTempProperty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [carouselImages, setCarouselImages] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        async function fetchdata() {
            try {
                const response = await axios.get("https://ranilgroup.com/api/all_properties.php");
                await Array.prototype.push.apply(data, response.data.result);
                console.log(data);
                const uniqueData = data.reduce((acc, current) => {
                    const x = acc.find(item => item.id === current.id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                console.log(uniqueData);
                setproperty([...uniqueData]);
                setTempProperty([...uniqueData]);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }

        }


        fetchdata();
    }, []);
    useEffect(() => {
        if (selectedProperty) {
            const imagesForProperty = data
                .filter(item => item.id === selectedProperty.id)
                .map(item => item.image_name);

            setCarouselImages(imagesForProperty);
            console.log("Hello Ji kya haal hai");
            setShowmodal(true);
        } else {
            console.log("NHI AAYA ABHI TAK CLICK");
        }
    }, [selectedProperty]);
    const contact = () => {
        setLoader(true);
        const data_to = { id: Cookies.get('id'), to: selectedProperty.email, name: Cookies.get('name') };
        axios.post("https://ranilgroup.com/api/contact_property.php", data_to).then((response) => {
            setLoader(false);
            if (response.data.result == "Thankyou for contacting us , we will get back to you") {
                alert(" Email Sent to respected owner");
            } else {
                console.log("error");
            }

        });
    }

    const [sortOrder, setSortOrder] = useState(null); // initial sorting order
    const [selectedBhk, setSelectedBhk] = useState(null); // selected number of bedrooms
    console.log("This is the image array ", carouselImages);
    console.log(carouselImages);
    carouselImages.map((item) => {
        console.log("Hello i am image", item);
    })
    const handleSort = (order) => {
        const sortedData = [...property];

        if (order === 'asc') {
            sortedData.sort((a, b) => a.price - b.price);
        } else if (order === 'desc') {
            sortedData.sort((a, b) => b.price - a.price);
        }

        setproperty(sortedData);
        setSortOrder(order);
    };

    const handleBhkFilter = (bhk) => {
        if (bhk === selectedBhk) {
            setSelectedBhk(null);
            setproperty(tempproperty);
        }
        else if (bhk === 'more') {
            const filteredData = tempproperty.filter(item => item.rooms > 4);
            setproperty(filteredData);
            setSelectedBhk(bhk);
        }
        else {
            const filteredData = tempproperty.filter(item => item.rooms === bhk);
            setproperty(filteredData);
            setSelectedBhk(bhk);
        }
    };


    return (
        <div className='container my-3'>
            <h3 className='text-center' style={{ fontFamily: "Noto Serif JP, serif" }}>Explore Exclusive Properties and Prime Land for Sale with Ranil Group</h3>
            <div className='row mt-3' style={{ justifyContent: "space-between", borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                <div className="dropdown" style={{ width: "50%", display: "flex", justifyContent: "start" }}>
                    <button className=" dropdown-toggle custom-gradient-button" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: "black", background: "white", boxShadow: "4px 4px 7px 0px grey", border: "none", margin: "10px" }} >
                        {
                            sortOrder ? sortOrder : "Sort By Price"
                        }
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#" onClick={() => handleSort('asc')}
                            disabled={sortOrder === 'asc'}>Low to High</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleSort('desc')}
                            disabled={sortOrder === 'desc'}>High To Low</a></li>
                    </ul>
                </div>
                <div className="dropdown" style={{ width: "50%", display: "flex", justifyContent: "end" }}>
                    <button className="custom-gradient-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: "black", background: "white", boxShadow: "4px 4px 7px 0px grey", border: "none", margin: "10px" }}>
                        {
                            selectedBhk ? `${selectedBhk} BHK` : "Sort By BHK"
                        }
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#" onClick={() => handleBhkFilter('1')}
                            disabled={selectedBhk === '1'}>1 BHK</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleBhkFilter('2')}
                            disabled={selectedBhk === '2'}>2 BHK</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleBhkFilter('3')}
                            disabled={selectedBhk === '3'}>3 BHK</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleBhkFilter('more')}
                            disabled={selectedBhk === 'more'}>more</a></li>
                    </ul>
                </div>



            </div>
            <div className='row'>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    property.length > 0 ? (
                        property.map((item, key) => (
                            <>
                                <div className='col-md-4 mt-3'>
                                    <Allpropertycard city={item.city} image_name={item.image_name} address={item.address} phone={item.phone_number} state={item.state} description={item.description} price={item.price} item={item} setSelectedProperty={setSelectedProperty} type={item.type_rent_or_sell} />
                                </div>
                            </>
                        ))
                    ) : (
                        <p>No data available.</p>
                    )
                )}
            </div>


            {/* modal for knowmore about property  */}
            {
                showmodal ?
                    <div className="modal fade" id="knowmoremodal" tabIndex="-1" aria-labelledby="knowmoremodalLabel" aria-hidden="true">
                        {loader ? <form className='signup_form' style={{ display: loader ? "flex" : "none", flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
                            <p>We are sending an email to Owner with your contact details <br />
                                <p className="loader"></p>
                            </p>
                        </form> :
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                                            {selectedProperty.city} {selectedProperty.state}</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='container'>
                                            <div className='row'>
                                                { carouselImages.length === 1 ?
                                                <img src={`https://ranilgroup.com/Upload_pic/${selectedProperty.image_name}`} alt='property image' style={{ objectFit: "cover", height: "300px" }}></img> :
                                                <div  id="carouselExample" class="carousel slide">
                                                    
                                                    <div className="carousel-inner">
                                                        {
                                                            carouselImages.map((item, key) => (
                                                                
                                                                <>
                                                                {/* <p>The key is {key}</p> */}
                                                                    <div key={key} className={`carousel-item ${key === 0?"active":""}`}>
                                                                        <img src={`https://ranilgroup.com/Upload_pic/${item}`} alt="..." style={{ objectFit: "cover", height: "300px" }} />
                                                                    </div>
                                                                </>
                                                            ))
                                                        }

                                                    </div>   
                                                
                                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Previous</span>
                                                    </button>
                                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Next</span>
                                                    </button>
                                                </div>
}
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        selectedProperty.land ? <h6><strong>Total Area: </strong> {selectedProperty.area}<small>Sq.ft</small></h6> : <h6><strong>Carpet Area: </strong> {selectedProperty.area}<small>Sq.ft</small></h6>
                                                    }
                                                </div>
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        selectedProperty.land ? "" : <h6><strong>Configuration: </strong> {selectedProperty.rooms} Bedrooms, {selectedProperty.bathroom} Bathrooms, {selectedProperty.balconies} Balconies </h6>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        selectedProperty.land ? <h6><strong>Property Status: </strong> {selectedProperty.status === 1 ? "Ready to move" : "Pending"}</h6> : <h6><strong>Property Status: </strong> {selectedProperty.status === 1 ? "Ready to move" : "Under Construction"}</h6>
                                                    }
                                                </div>
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        selectedProperty.land ? "" : <h6><strong>Furnishing: </strong> {selectedProperty.type === 1 ? "Semi Furnished" : selectedProperty.type === 2 ? "Fully Furnished" : "UnFurnished"}</h6>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-6 col-6'>
                                                    <h6><FontAwesomeIcon icon={faLocationDot} style={{ marginRight: "10px" }} /><strong>Address: </strong></h6>
                                                    <p>{selectedProperty.address} {selectedProperty.city} {selectedProperty.state} {selectedProperty.pincode}</p>
                                                </div>
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        selectedProperty.land ? "" : <h6><strong>No of Floors: </strong> {selectedProperty.floors}</h6>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <h6><FontAwesomeIcon icon={faTag} style={{ color: "#1c5ac4", marginRight: "10px" }} /><strong>Price: </strong>Only at  {'\u20B9 ' + selectedProperty.price} {
                                                    selectedProperty.rent ? "Per Month" : ""
                                                }</h6>
                                            </div>
                                            <div className='row mt-2'>
                                                <h6><strong>Description:</strong></h6>
                                                <p>{selectedProperty.description}</p>
                                            </div>
                                            <div className='row mt-2'>
                                                <h5><strong>Click on Contact Now to contact with owner</strong></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {
                                            Cookies.get('name') ? <button className="custom-gradient-button" onClick={contact} style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Contact Now</button> : <p>Please login to Contact owner!</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div > : ""
            }

        </div>
    )
}
