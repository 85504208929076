import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Forgot = () => {
    const navigate = useNavigate();
    const [input,setinput] = useState({email:"",pass_1:"",pass_2:"",otp:""});
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const submit_form = async (event) => {
        event.preventDefault();
        const data_form = {otp:input.otp,email:input.email,pass_1:input.pass_1,pass_2:input.pass_2};
        if(data_form.pass_1!==data_form.pass_2){
        setAlert(true);
        setMessage("Password do not match");
        }else{
            // // console.log("yoo");
            axios.post("https://ranilgroup.com/api/forgot.php",data_form).then((response)=>{
                setAlert(true);
                if(response.status=="200"){
                
                if(response.data.result=="Password Reset successfully"){
                    navigate("/login");
                }else{
                    // setMessage("Try again later");
                    setMessage(response.data.result);
                }
                }else if(response.data.status=="400"){
                    setMessage("technical error");
                }
            });
        }
    }
    const handlechange = (e)=>{
        // // console.log("hello");
        setinput({...input,[e.target.name]:e.target.value});
        // // console.log(input);
    }

    const verifyotp = (e)=>{
        const data_form = {otp:input.otp,email:input.email};
        // // console.log(data_form);
        setLoader(true);
        axios.post("https://ranilgroup.com/api/verify_otp.php",data_form).then((response)=>{
            setAlert(true);
            if(response.status=="200"){
            setMessage(response.data.result);

            }else if(response.data.status=="400"){
                setMessage("technical error");

            }
            setLoader(false);
        });
    }
    
    
    return (
        <div>
            <div class="background">
                <div class="shape"></div>
                <div class="shape"></div>
            </div>
            {loader ? <form className='signup_form' style={{ display: loader ? "flex" : "none", flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
          <p>We are sending an email to verify your account<br />
            <p className="loader"></p>
          </p>
          </form>:
            <form className='login_form'>
                <h3>Change Your Password</h3>
                <label htmlFor="username">Email</label>
                <input onChange={handlechange} className='input_login' name='email' type="text" placeholder="Email" id="username" value={input.email} required />
                <label htmlFor="username">OTP</label>
                <input onChange={handlechange} className='input_login' name='otp' type="text" placeholder="Enter Otp sent on above email" id="otp" />
                <div className='already'>
                <p onClick={verifyotp} style={{color:"blue",textAlign: "end",marginBottom:"0px"}}>Verify Email</p>
                </div>
                <label htmlFor="password">New Password</label>
                <input onChange={handlechange} className='input_login' name='pass_1' type="password" placeholder="New Password" id="password" />
                <label htmlFor="password">Confirm New Password</label>
                <input onChange={handlechange} className='input_login' name='pass_2' type="password" placeholder="Confirm New Password" id="password" />
                <button onClick={submit_form} className='login_button'>Change Now</button>
                {alert?<div class="alert fogetpass alert-danger" role="alert">
                {message}
                </div>:""}
        
            </form>
}
        </div>

    )
}

export default Forgot
