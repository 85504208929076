import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Card from './Card'

const TopProject = () => {
    const scrollableContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (scrollOffset) => {
         const updatedScrollPosition = scrollPosition + scrollOffset;
        const newScrollPosition = Math.max(updatedScrollPosition, 0);
        // console.log(newScrollPosition);
        if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollLeft = newScrollPosition;
            setScrollPosition(newScrollPosition);
        }
    };
    return (
        <div className='container my-3'>
            <div className='row' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
                <h2>Top Project</h2>
            </div>

            <div className='row'>
                <div className='col-md-1' style={{width:"0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <button
                        className="scrollbuttonleft"
                        onClick={() => handleScroll(-100)}
                        style={{boxShadow:"-3px 4px 4px 0px #b8b8b8", border:"none"}}
                    >
                        <span><FontAwesomeIcon icon={faChevronLeft} /></span>
                    </button>
                </div>
                <div className='col-md-10 col-12' style={{width:"95%"}}>
                    <div className='container d-flex my-3' ref={scrollableContainerRef} style={{ overflowX: "auto", alignItems: "center" }}>

                        <div className=''>
                            <Card imagesrc="./Image/card.jpg" name="PANIPAT" description="SCO(Shops cum office) in sec: 13-17 Shop cum offices are a blend of retail and office space which provides hybrid working space." />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/about_us.png" name="SUSHANT LOK" description="Builder floors in Sushant Lok, Gurgaon A private, spacious residential property.
                    " price="2 CR onwards" />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/Hero_section.png" name="MALIBO TOWN" description="Builder floors in Malibo Town, Gurgaon A private, spacious residential property." price="2 CR onwards" />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/card2.jpg" name="ROSEWOOD CITY" description="Builder floors in Rosewood city, Gurgaon A private, spacious residential property." price="2 CR onwards" />
                        </div>

                    </div>
                </div>
                <div className='col-md-1' style={{width:"0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <button
                        className="scrollbuttonright "
                        onClick={() => handleScroll(100)}
                        style={{border:"none", boxShadow:"3px 4px 4px 0px #b8b8b8"}}
                    >
                        <span><FontAwesomeIcon icon={faChevronRight} /></span>
                    </button>
                </div>

            </div>
        </div>
    )
}

export default TopProject
