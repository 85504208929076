import React, { useState } from 'react'
import Location from './Location'
import Success from './Success'
import PropertyProfile from './PropertyProfile'
import Uploadphotos from './Uploadphotos'
import Pricing from './Pricing'
import Postedsuccessfully from './Postedsuccessfully'
import SideBar from './SideBar'

const Moredetails = () => {
  const [progress, setProgress] = useState(0);
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-3'>
          <SideBar progress={progress}/>
        </div>
        <div className='col-md-5'>
          {progress === 0?<Location progress={progress} setProgress={setProgress} />: progress === 1?<PropertyProfile progress={progress} setProgress={setProgress}/>: progress === 2?<Uploadphotos progress={progress} setProgress={setProgress} />: progress === 3?<Pricing progress={progress} setProgress={setProgress} />:<Postedsuccessfully/>}
        </div>
        <div className='col-md-4'>
          <Success progress={progress} />
        </div>
      </div>

    </div>
  )
}

export default Moredetails
