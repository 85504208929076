import React from 'react'

const Oursubsidiaries = () => {
  return (
    <div className='container' style={{marginBottom:"1rem"}}>
      <div className='row my-3' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
        <h2 >Subsidiaries</h2>
      </div>
      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='container'  style={{position:"relative"}}>
              <div id='background-image' ></div>
              <div className='row text-center mt-2'>
                <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "700", color:"black" }}>
                  Ranil International Schools
                </h1>
              </div>
              <div className='row text-center'>
                <p style={{color:"white", padding:"35px"}}>
                  Explore Ranil International School, established in 2016 in Badhshahpur, Gurugram. We provide top-tier education from nursery to grade 12, following the CBSE curriculum. Our modern campus features well-equipped classrooms, a library, a computer lab, and a playground, fostering well-rounded individuals. Our dedicated educators emphasize character development and offer diverse extracurricular activities for holistic growth. Join us in shaping future leaders.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className='container'>
              <div id='background-image2'></div>
              <div className='row text-center mt-2'>
                <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "700", color:"white" }}>
                  Lady Florence Schools
                </h1>
              </div>
              <div className='row text-center'>
                <p style={{color:"white", padding:"35px"}}>
                  Discover Lady Florence Public School, a CBSE-affiliated co-ed institution in Gurgaon, established in 1999 and managed by Sh. Dalchand Raghav Educational Society. Our commitment to academic excellence is evident through our modern facilities, including a swimming pool, ballroom, smart classrooms, and full air-conditioning. Our affordable fee structure ensures quality education for all. With three branches in Gurgaon, we shape young minds, emphasize character development, and prepare students for a brilliant future. Join us, where education knows no bounds, and excellence is our way of life.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
          <div className='container'>
              <div id='background-image3'></div>
              <div className='row text-center mt-2'>
                <h1 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "700", color:"black" }}>
                  Ranil Showrooms
                </h1>
              </div>
              <div className='row text-center'>
                <p style={{color:"white", padding:"35px"}}>
                Explore Ranil Enterprises in Gurgaon, home to premier Mahindra car and electric scooty showrooms. Our commitment to quality and innovation extends beyond education, offering a wide range of Mahindra vehicles known for their rugged utility and style. Our electric scooty showrooms provide eco-friendly urban mobility solutions, contributing to a greener future. Join us at Ranil Enterprises, where we redefine automotive experiences in Gurgaon.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default Oursubsidiaries
