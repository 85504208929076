import React, { useState } from 'react'

import { Link } from 'react-router-dom'

const DashboardCard = ({type,basic_details_id, state,city,address, imagesrc, price, description,floors,rooms, setselectedCard, name, button, navigation}) => {
    // // console.log("State is "+ state);
    const showdata = ()=>{
        setselectedCard({
        type,
        basic_details_id,
        city,
        state: state,
        address,
        price,
        description,
        })
    }
     
    return (
        <>
        <div className='row dashboard-card-responsive'>
            <div className='col-md-6' style={{ display: "flex", justifyContent: "center" }}>
                <img src={imagesrc} className='img-fluid2 image-responsive' alt='image' style={{maxHeight:"330px"}}></img>
            </div>
            <div className='col-md-6'>
                <div className='row'>
                    <h5><strong>{name}</strong></h5>
                </div>
                <div className='row'>
                    <p>{address}</p>
                    <p>Total Floors: {floors}</p>
                    <p>Total Rooms: {rooms}</p>
                </div>
                <div  className='row'>
                    <p>{price ? '\u20B9 ' + price : ''}</p>
                </div>
                <div className='row' style={{ marginBottom: "1rem", justifyContent: "center" }}>
                    <Link to={navigation}><button className="custom-gradient-button-card my-2" data-bs-toggle="modal" onClick={showdata} data-bs-target="#exampleModal" >{button}</button></Link>
                </div>
            </div>
            
        </div>

        
     </>
    )
}

export default DashboardCard
