import React, { useState } from "react";
import "../css/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"
export function Navbar() {
    const navigate = useNavigate();
    const [username, setusername] = useState();
    

    return (
        <>
            <nav className="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar" style={{ position: "relative" }}>
                <div className="container">
                    <a className="navbar-brand collapse_logo" href="/"><img src="./Image/logo.png" alt="logo" style={{ width: "100%" }}></img></a>
                    <div className="social-media order-lg-last">
                        <button className="custom-gradient-button" onClick={() => { navigate('/Buyandsell') }}>Buy/Sell Properties</button>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="user_icon order-lg-last collapse navbar-collapse" id="ftco-nav">
                        
                        {Cookies.get('name') ? <span className="user_login" style={{ background: "white", color: "black", border: "1px solid black" }}><Link to="/Dashboard">{Cookies.get('name')[0].toUpperCase()}</Link></span> :
                            <a href="/Signup"><span style={{ marginLeft: "10px" }} ><FontAwesomeIcon icon={faUser} /></span></a>
                        }
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav ml-auto mr-md-3">

                            <li className="nav-item"><Link to="/Investor" className="nav-link">Investors</Link></li>
                            <li className="nav-item"><Link to="/Subsidiary" className="nav-link">Subsidiary</Link></li>
                            <li className="nav-item"><Link to="/Contact" className="nav-link">Contact Us</Link></li>
                            <li className="nav-item"><Link to="/Allproperties" className="nav-link">All Properties</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
