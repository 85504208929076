import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Card from './Card'

const Upcomingprojects = () => {
    const scrollableContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (scrollOffset) => {
        const updatedScrollPosition = scrollPosition + scrollOffset;
        const newScrollPosition = Math.max(updatedScrollPosition, 0);
        // console.log(newScrollPosition);
        if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollLeft = newScrollPosition;
            setScrollPosition(newScrollPosition);
        }
    };

    return (
        <div className='container'>
            <div className='row mt-2' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
                <h2>
                    Upcoming Projects
                </h2>
            </div>
            <div className='row'>
                <div className='col-md-1' style={{width:"0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <button
                        className="scrollbuttonleft"
                        onClick={() => handleScroll(-100)}
                        style={{boxShadow:"-3px 4px 4px 0px #b8b8b8", border:"none"}}
                    >
                        <span><FontAwesomeIcon icon={faChevronLeft} /></span>
                    </button>
                </div>
                <div className='col-md-10' style={{width:"95%"}}>
                    <div className='container d-flex my-3' ref={scrollableContainerRef} style={{ overflowX: "auto", alignItems: "center" }}>
                        <div className=''>
                            <Card imagesrc="./Image/construction1.jpg" name="Sec-95, 79 Gurgaon" description="Residential floors 2BHK, 3BHK, 2+1 BHK in four storey building."  />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/construction2.jpg" name="Sec-5 Sohna" description="Residential floors 2BHK, 3BHK, 2+1 BHK in four storey building."  />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/construction4.jpg" name="SCO in Sec-71" description="Shops cum Offices for commercial purposes in Sector 71, Gurgaon." />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/construction5.jpg" name="Sec-30 Gurgaon" description="Residential floors 2BHK, 3BHK, 2+1 BHK in four storey building." />
                        </div>
                        <div className=''>
                            <Card imagesrc="./Image/construction4.jpg" name="SCO in Sec-29" description="Shops cum Offices for commercial purposes in Sector 29, Rewari. "/>
                        </div>
                    </div>
                </div>
                <div className='col-md-1' style={{width:"0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <button
                        className="scrollbuttonright "
                        onClick={() => handleScroll(100)}
                        style={{border:"none", boxShadow:"3px 4px 4px 0px #b8b8b8"}}
                    >
                        <span><FontAwesomeIcon icon={faChevronRight} /></span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Upcomingprojects
