import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import PropertyProfile from './PropertyProfile';
import axios from 'axios';
import Cookies from "js-cookie"
import React, { useState } from 'react'

const Location = ({ progress, setProgress }) => {
    const [alert, setAlert] = useState(false);
    const [showcity, setshowcity] = useState(false);
    const [showadress, setshowadress] = useState(false);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [adress, setAdress] = useState('');
    const [pincode, setPincode] = useState('');
    const [alertmessage, setalertmessage] = useState('');
    // console.log(showcity);
    const handleClick = () => {
        if(pincode.length >6 || pincode.length <6){
            setAlert(true);
            setalertmessage("Please enter correct pincode")
        }
        else if (state !== '' && city !== '' && adress != '' && pincode != '') {
            setAlert(false);
            const to_data = { id: Cookies.get("id"), state: state, city: city, address: adress, pincode: pincode };
            axios.post("https://ranilgroup.com/api/basic_details.php", to_data).then((response) => {
                // console.log(response);
                if (response.data.result == "Success") {
                    setProgress(progress + 1);
                }

            });

        }
        else{
            setAlert(true);
        }
    }
    return (
        <div className='container'>
            <div className='row my-3'>
                <div>
                    <h3 style={{ fontFamily: "Noto Serif JP, serif" }}>Where is your property Located?</h3>
                    <small>An accurate location help you connect with right buyers</small>
                    {
                        alert?<div className="alert alert-danger" role="alert">
                        {alertmessage}
                    </div> :""
                    }
                </div>
                <div className='my-2'>
                    <label>Enter your state</label>
                    <input type='text' className="form-control" required value={state} id="State" placeholder='enter your state' onChange={(e) => { setState(e.target.value) }} ></input>
                </div>
                {
                    showcity ? <div className='my-2'><label>Enter your City</label>
                        <input type='text' className="form-control" required value={city} id="State" placeholder='enter your state' onChange={(e) => { setCity(e.target.value) }}></input>
                        <button className='custom-gradient-button my-2' style={{ padding: "5px 13px", width: "30%", border: "1px solid black", display: showadress ? "none" : "block" }} onClick={() => { if (city !== '') { setshowadress(true) } }} >
                            Done
                        </button></div> : <button className='custom-gradient-button my-2' style={{ padding: "5px 13px", width: "30%", border: "1px solid black" }} onClick={() => { if (state !== '') { setshowcity(true) } }} >
                        Done
                    </button>
                }
                {
                    showadress ? <div className='my-2'>
                        <label>Enter your Complete adress</label>
                        <input type='text' className="form-control" required value={adress} id="adress" placeholder='Complete adress' onChange={(e) => { setAdress(e.target.value) }}></input>
                        <label>Enter your property pincode</label>
                        <input type='number' className="form-control" required value={pincode} id="adress" placeholder='PINCODE' onChange={(e) => { setPincode(e.target.value) }}></input>
                        <button className='custom-gradient-button my-2' style={{ padding: "5px 13px", width: "30%", border: "1px solid black" }} onClick={handleClick} >
                            Done
                        </button>
                    </div> : ""
                }

            </div>
        </div>
    )
}

export default Location
