import axios, { formToJSON } from 'axios';
import Cookies from 'js-cookie';
import React from 'react'

const Allpropertycard = ({ image_name, address, city, state, description, price, item, setSelectedProperty, type, setCarouselImages }) => {
    const handleclick = () => {
        setSelectedProperty(item);
        // console.log(item);
        // console.log("hello");
        const data_to = { batch_id: item.basic_details_id };
        // console.log(data_to);
        
        axios.post("https://ranilgroup.com/api/click.php", data_to).then((response) => {
            // console.log(response);
        });
    }
    return (
        <div class="card" style={{ width: "21rem", boxShadow: "7px 7px 13px 0px grey", borderRadius: "40px 0px" }}>
            <img src={`https://ranilgroup.com/Upload_pic/${image_name}`} class="card-img-top" alt="..." style={{ objectFit: "cover", height: "200px" }} />
            <div class="card-body">
                
                <h5 class="card-title"><strong>{city} {state}</strong> { type === "Rent"? <span class="badge text-bg-danger">Rent</span>:""}</h5>
                <h6>{address}</h6>
                <p class="card-text text-truncate">{description}</p>
                <h6>{item.type_rent_or_sell=="Rent"?('\u20B9 ' + price +" per month"):('\u20B9 ' + price)}</h6>
                <center><button className="custom-gradient-button-responsive mx-2" style={{ width: "50%" }} data-bs-toggle="modal" data-bs-target="#knowmoremodal" onClick={handleclick} >Know More</button></center>
            </div>
        </div>
    )
}

export default Allpropertycard
