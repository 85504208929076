import React from 'react';
import { useNavigate } from 'react-router-dom';

const Subsidiaries = () => {
  const navigate = useNavigate();
  return (
    <div className='container'>
      <h1 className='text-center my-3' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "500" }}>Welcome to Ranil Group Subsidiaries</h1>

      <div className='row subsidiary'>
        <div className="card mb-3" style={{maxWidth: "100%", paddingLeft:"0px", boxShadow:"8px 5px 7px 0px grey", border:"none"}}>
          <div className="row g-0">
            <div className="col-md-4" style={{display:"flex"}}>
              <img src="./Image/lady_florence.jpg" className="img-fluid rounded-start" style={{width:"100%"}} alt="Lady florence" />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h3 className="card-title text-center" style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>Lady Florence Public School</h3>
                <p className="card-text">Discover Lady Florence Public School, a CBSE-affiliated co-ed institution in Gurgaon, established in 1999 and managed by Sh. Dalchand Raghav Educational Society. Our commitment to academic excellence is evident through our modern facilities, including a swimming pool, ballroom, smart classrooms, and full air-conditioning. Our affordable fee structure ensures quality education for all. With three branches in Gurgaon, we shape young minds, emphasize character development, and prepare students for a brilliant future. Join us, where education knows no bounds, and excellence is our way of life.</p>
                <center><button className="custom-gradient-button" style={{padding:"5px 17px", width:"30%", border:"1px solid black"}} onClick={()=>{navigate('/Ladyflorence')}}>Know More</button></center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row subsidiary'>
        <div className="card mb-3" style={{maxWidth: "100%", paddingLeft:"0px", boxShadow:"8px 5px 7px 0px grey", border:"none"}}>
          <div className="row g-0">
            <div className="col-md-4" style={{display:"flex"}}>
              <img src="./Image/ranil_international.png" className="img-fluid rounded-start" style={{width:"100%"}} alt="Lady florence" />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h3 className="card-title text-center" style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>Ranil International School</h3>
                <p className="card-text">Explore Ranil International School, established in 2016 in Badhshahpur, Gurugram. We provide top-tier education from nursery to grade 3rd, following the CBSE curriculum. Our modern campus features well-equipped classrooms, a library, a computer lab, and a playground, fostering well-rounded individuals. Our dedicated educators emphasize character development and offer diverse extracurricular activities for holistic growth. Join us in shaping future leaders.</p>
                <center><button className="custom-gradient-button" style={{padding:"5px 17px", width:"30%", border:"1px solid black"}} onClick={()=>{navigate('/Ranilinternational')}}>Know More</button></center>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row subsidiary'>
        <div className="card mb-3" style={{maxWidth: "100%", paddingLeft:"0px", boxShadow:"8px 5px 7px 0px grey", border:"none"}}>
          <div className="row g-0">
            <div className="col-md-4" style={{display:"flex"}}>
              <img src="./Image/Showrooms.jpg" className="img-fluid rounded-start" style={{width:"100%"}} alt="Lady florence" />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h3 className="card-title text-center" style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>Ranil Car Showroom</h3>
                <p className="card-text">Explore Ranil Enterprises in Gurgaon, home to premier Mahindra car and electric scooty showrooms. Our commitment to quality and innovation extends beyond education, offering a wide range of Mahindra vehicles known for their rugged utility and style. Our electric scooty showrooms provide eco-friendly urban mobility solutions, contributing to a greener future. Join us at Ranil Enterprises, where we redefine automotive experiences in Gurgaon.</p>
                <center><button className="custom-gradient-button" style={{padding:"5px 17px", width:"20%", border:"1px solid black"}} onClick={()=>{navigate('/Ranilshowroom')}}>Know More</button></center>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default Subsidiaries;
