import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Cookies from 'js-cookie';

const Personaldetails = ({userdata, fname , lname, setFnameedit, setLnameedit, fnameedit, lnameedit,sidenavbar, uppernavbar}) => {
    const [occupation2, setOccupation2] = useState(false);
    const [state2, setState2] = useState(false);
    const [city2, setCity2] = useState(false);
    const [pincode2, setPincode2] = useState(false);
    const [address2, setAddress2] = useState(false);
    const [forminput,setforminput] = useState({fname:userdata.fname,lname:userdata.lname,state:'',city:'',occupation:'',address:'',pincode:''});
    const handlechange = (e)=>{
        setforminput({...forminput,[e.target.name]:e.target.value});
        // console.log(forminput);
    }
    const savedetails = ()=>{
        const data_to = ({id:Cookies.get('id'),fname:forminput.fname?forminput.fname:fname,lname:forminput.lname?forminput.lname:userdata.lname,state:forminput.state?forminput.state:userdata.state,city:forminput.city?forminput.city:userdata.city,occupation:forminput.occupation?forminput.occupation:userdata.occupation,address:forminput.address?forminput.address:userdata.address,pincode:forminput.pincode?forminput.pincode:userdata.pincode});
        axios.post("https://ranilgroup.com/api/update_details.php",data_to).then((response)=>{
            // console.log(response);
            if(response.data.result=="updated"){
                alert("data updated Successfully");
                
                Cookies.set('name',forminput.fname?forminput.fname:fname, { expires: 7 });
                window.location.reload(false);
            }else{
                alert("technical error");
            }
        });
        // console.log(forminput);
    // // console.log("hello");
    }

  return (
    <div className={uppernavbar?"container my-5":sidenavbar?'container my-5 marginleftdashboard':'container my-5 marginleftdashboardresponsive'} style={{ paddingLeft:"5%", borderRadius: "25px", boxShadow: "6px 5px 7px 0px grey", width: uppernavbar?"95%":sidenavbar?"81%":"91%"}}>
                <h3 className='text-center' style={{ fontFamily: "Noto Serif JP, serif" }}>Welcome to Ranil Group {userdata.fname}</h3>
                <h5 style={{ fontFamily: "Noto Serif JP, serif" }}><strong>Your personal Details</strong></h5>
                <div className='row mt-5'>
                    <div className='col-md-6 col-6'>
                        <span><h5>First Name<FontAwesomeIcon icon={faPenToSquare} onClick={() =>{ setFnameedit(!fnameedit) }} /></h5></span>
                        {
                            fnameedit ? <input type='text' onChange={handlechange} name='fname' className='form-control' placeholder={fname.toUpperCase()} style={{ width: uppernavbar?"70%":"35%" }}></input> : <h6>{fname.toUpperCase()}</h6>
                        }
                    </div>
                    <div className='col-md-6'>
                        <h5>Last Name <FontAwesomeIcon icon={faPenToSquare} onClick={() => {setLnameedit(!lnameedit) }}></FontAwesomeIcon></h5>
                        {
                            lnameedit ? <input type='text' name='lname' onChange={handlechange} className='form-control' placeholder={lname?lname.toUpperCase():''} style={{ width: uppernavbar?"70%":"35%" }}></input> : <h6>{lname?lname.toUpperCase():''}</h6>
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <h5>Email id <small>(You can't edit your mail id)</small></h5>
                        <h6>{userdata.email}</h6>
                    </div>
                    <div className='col-md-6'>
                        <h5>Phone number <small>(You can't edit your phone number)</small></h5>
                        <h6>{userdata.phone}</h6>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <h5>You are a </h5>
                        <h6>Owner</h6>
                    </div>
                    <div className='col-md-6'>
                        {/* Yaha input tabhi aaega agar user ka data already available na ho  */}
                        <h5>Your occupation <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setOccupation2(!occupation2) }} /></h5>
                        {
                            occupation2?<input type='text' onChange={handlechange} name='occupation' className='form-control' placeholder={userdata.occupation?userdata.occupation:'Enter your occupation'} style={{width: uppernavbar?"70%":"35%"}}></input>:<p>{userdata.occupation}</p>
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <h5>Your State <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setState2(!state2) }} /></h5>
                        {
                            state2?<input type='text' onChange={handlechange} name='state' className='form-control' placeholder={userdata.state?userdata.state:'Enter your State'} style={{ width: uppernavbar?"70%":"35%" }}></input>:<p>{userdata.state}</p>
                        }
                    </div>
                    <div className='col-md-6'>
                        <h5>Your City <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setCity2(!city2) }} /></h5>
                        {
                            city2?<input type='text' onChange={handlechange} name='city' className='form-control' placeholder={userdata.city?userdata.city:'Enter your City'} style={{ width: uppernavbar?"70%":"35%" }}></input>:<p>{userdata.city}</p>
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <h5>Your Complete Address <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setAddress2(!address2) }} /></h5>
                        {
                            address2?<input type='text' onChange={handlechange} name='address' className='form-control' placeholder={userdata.address?userdata.address:'Enter your Complete address'} style={{width: uppernavbar?"70%":"35%"}}></input>:<p>{userdata.address}</p>
                        }
                    </div>
                    <div className='col-md-6'>
                        <h5>Your Pincode <FontAwesomeIcon icon={faPenToSquare} onClick={() => { setPincode2(!pincode2) }} /></h5>
                        {
                            pincode2?<input type='number' onChange={handlechange} name='pincode' className='form-control' placeholder={userdata.pincode?userdata.pincode:'Enter your Pincode'} style={{ width: uppernavbar?"70%":"35%" }}></input>:<p>{userdata.pincode}</p>
                        }
                    </div>
                </div>
                <div className='row my-3'>
                    <center><button className='custom-gradient-button-responsive my-2' onClick={savedetails}>
                        Save details
                    </button></center>
                </div>
            </div>
            
  )
}

export default Personaldetails
