import React from 'react';

const SideBar = ({progress}) => {
    return (
        <div class="v-progress my-3 sticky-top" style={{ borderRadius: "20px", boxShadow: "6px 4px 7px 0px grey", background: "#cecece" }}>
            <ul>
                <li class={`v-progress-item ${progress >=0 ?'completed':''}`}>Basic Details</li>
                <li class={`v-progress-item ${progress >=1? "completed":"inprogress"}`}>Location Details</li>
                <li class={`v-progress-item ${progress === 1?'inprogress':''} ${progress >= 2?'completed':''}`}>Property Profile</li>
                <li class={`v-progress-item ${progress === 2?'inprogress':''} ${progress >= 3?'completed':''}`}>Upload photos</li>
                <li class={`v-progress-item ${progress === 3?'inprogress':''} ${progress >= 3?'completed':''}`}>Pricing</li>
            </ul>
        </div>
    );
}

export default SideBar;
