import React from 'react'

const Howitworks = () => {
  return (
    <div>
      <h3 style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "500" }}>How it works</h3>
            <div className='row my-3'>
              <div className='col-md-6 my-2 col-12'>
                <div className='row'>
                  <img src='./Image/add_details.png' alt='house_logo' style={{ width: "26%", marginBottom: "10px" }}></img>
                  <h5><strong>Add details of your property</strong></h5>
                </div>
                <p>Begin by telling us some basic details of your property like your property type, location, No of rooms etc.</p>
              </div>
              <div className='col-md-6 my-2 col-12'>
                <div className='row'>
                  <img src='./Image/upload_image.png' alt='upload Image' style={{ width: "25%", marginBottom: "10px" }}></img>
                  <h5><strong>Upload Photos or videos</strong></h5>
                </div>
                <div className='row'>
                  <p>Upload Photos or Videos of your property either from your desktop or your mobile phone with ease. </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 my-2 col-12'>
                <div className='row'>
                  <img src='./Image/house_logo.jpg' alt='house_logo' style={{ width: "26%", marginBottom: "10px" }}></img>
                  <h5><strong>Add pricing and ownership</strong></h5>
                </div>
                <div className='row'>
                  <p>Just update your pricing details and your property is good to go live on our website.</p>
                </div>
              </div>
              <div className='col-md-6 my-2 col-12'>
                <div className='row'>
                  <img src='./Image/user_dashboard.png' alt='user_dashboard' style={{ width: "21%", marginBottom: "10px" }}></img>
                  <h5><strong>Check user dashboard for updates</strong></h5>
                </div>
                <div className='row'>
                  <p>Get contact details of your buyer or tenants easily on your dashboard.</p>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Howitworks
