import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Cookies from 'js-cookie';

const Uploadphotos = ({ progress, setProgress }) => {
  const [selimg, setselimg] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      // Handle the uploaded files here, e.g., send them to a server or process them.
      setselimg(true);
      setFiles(acceptedFiles);
      console.log('Selected Files:', acceptedFiles);
    },
  });

  const Uploadpic = async () => {
    const formdata = new FormData();
    formdata.append('id', Cookies.get('id'));

    // Append all files to the FormData
    files.forEach((file, index) => {
      formdata.append(`file${index}`, file);
    });
    // console.log(formdata);
    // for (const pair of formdata.entries()) {
    //     console.log(pair[0] + ', ' + pair[1]);
    //   }
    try {
      const response = await axios.post(
        'https://ranilgroup.com/api/pic_upload.php',
        formdata,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.data.result === 'Success') {
        setselimg(false);
        setProgress(progress + 1);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    setLoading(true);
    Uploadpic();
  };

  return (
    <div className='container'>
      {/* ... (your other JSX code) ... */}
      <div className='row mt-2'>
        <p>Upload Your photos here</p>
        <div className='text-center'>
          <div {...getRootProps()} className='dropzone border rounded p-4'>
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faCloudArrowUp} />
            <p>Drag 'n' Drop some files here or</p>
            {selimg ? <span>Images Selected, Continue.. </span> : ''}
            <center>
              <p
                className='mb-0 custom-gradient-button'
                style={{ border: '1px solid black', width: '35%' }}
              >
                Click HERE
              </p>
            </center>
            <small className='text-muted'>
              Accepted file types: .jpg, .jpeg, .png, etc.
            </small>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        {loading ? (
          <div className='spinner-border' role='status'>
            <span class='visually-hidden'>Loading...</span>
          </div>
        ) : (
          <button
            className='custom-gradient-button my-2'
            style={{ padding: '5px 13px', width: '30%', border: '1px solid black' }}
            onClick={handleClick}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default Uploadphotos;
