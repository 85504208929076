import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Card from './Card'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Topinvestment = () => {
    const [property, setproperty] = useState([]);
    const [loading, setLoading] = useState(true);
    const scrollableContainerRef = useRef(null);
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (scrollOffset) => {
        const updatedScrollPosition = scrollPosition + scrollOffset;
        const newScrollPosition = Math.max(updatedScrollPosition, 0);
        // // console.log(newScrollPosition);
        if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollLeft = newScrollPosition;
            setScrollPosition(newScrollPosition);
        }
    };

    useEffect(() => {

        async function fetchdata() {
            try {
                const response = await axios.get("https://ranilgroup.com/api/get_properties.php?homepage");
                await Array.prototype.push.apply(property, response.data);
                
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchdata();
    }, []);

    return (
        <div className='container'>
            <div className='row mt-2' style={{ fontFamily: "Noto Serif JP, serif", fontWeight: "600" }}>
                <h2>
                    Top Investement Opportunity <button id="custom-button-topinvestement" onClick={() => { navigate('/Investement') }} >
                        See All Listing
                    </button>
                </h2>
            </div>
            <div className='row'>
                <div className='col-md-1' style={{ width: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                        className="scrollbuttonleft"
                        onClick={() => handleScroll(-100)}
                        style={{ boxShadow: "-3px 4px 4px 0px #b8b8b8", border: "none" }}
                    >
                        <span><FontAwesomeIcon icon={faChevronLeft} /></span>
                    </button>
                </div>
                <div className='col-md-10' style={{ width: "95%" }}>
                    <div className='container d-flex my-3' ref={scrollableContainerRef} style={{ overflowX: "auto", alignItems: "center" }}>

                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            property.length > 0 ? (
                                property.map((item, key) => (
                                    <div className='' key={key}>
                                        {/* Render your Card component with item data */}
                                        <Card imagesrc={item.img_path} name={item.name} description={item.purpose + " " + item.location + " " + item.scheme + " " + item.size} price={item.price} button="Invest Now" navigation="/Investement" />
                                    </div>
                                ))
                            ) : (
                                <p>No data available.</p>
                            )
                        )}
                    </div>
                </div>
                <div className='col-md-1' style={{ width: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                        className="scrollbuttonright "
                        onClick={() => handleScroll(100)}
                        style={{ border: "none", boxShadow: "3px 4px 4px 0px #b8b8b8" }}
                    >
                        <span><FontAwesomeIcon icon={faChevronRight} /></span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Topinvestment
