import React, { useEffect, useState } from 'react';
import "../css/Footer.css";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import Cookies from 'js-cookie';
export default function Footer() {
  const [loading, setLoading] = useState(false);
  const [inputs, setinputs] = useState({ email: "" });
  const [message, setMessage] = useState(false);
  const [showsubscribe, setShowSubscribe] = useState(true);
  const handlechange = (e) => {
    setinputs({ ...inputs, [e.target.name]: e.target.value });
  }

  useEffect(()=>{
    const data_to = {id:Cookies.get('id')};
    axios.post("https://ranilgroup.com/api/check_subscription.php",data_to).then((response)=>{
    // console.log(response.data.result);
    if(response.data.result){
      setShowSubscribe(false);
    }
    });

  });


  const submit_cust = (e) => {
    e.preventDefault();
    setLoading(true);
    const data_form = { email: inputs.email };
    axios.post("https://ranilgroup.com/api/footerjoin.php", inputs).then((response) => {
      // console.log(response);
      setLoading(false);
      if (response.data.result === "Thankyou") {
        setMessage(true);
      }
    });

  }
  return (
    <>
      <footer>

        <div className="row  mb-0 mt-5 mx-4 row-2 justify-content-xl-around justify-content-sm-between">
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 pt-4 order-1">
            <ul className="list-unstyled">
              <li className="mt-md-0 mt-4">Our Solution</li>
              <li><a href='/Buyandsell' style={{color:"black"}}>Sell Your Properties</a></li>
              <li><a href='/Investor' style={{color:"black"}}>Invest in Lands</a></li>
              <li><a href='/Contact' style={{color:"black"}}>Expert Advise</a></li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pt-4 order-2">
            <ul className="list-unstyled">
              <li className="mt-md-0 mt-4">Your needs</li>
              <li data-bs-toggle="modal" data-bs-target="#exampleModal">Terms and conditions </li>
              <li data-bs-toggle="modal" data-bs-target="#privacymodal">Privacy Policy</li>
              <li data-bs-toggle="modal" data-bs-target="#cookiemodal">Cookie Policy</li>

            </ul>
          </div>
          <div className="col-xl-auto text-left col-lg-4 col-md-4 col-sm-6 col-12 pt-4 my-sm-0 order-6 my-auto">
            <div className="input-group-lg input-group mb-3 mt-md-0 mt-4">
              {showsubscribe?
              loading ? <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div> : message ? <h3>Thankyou for subscribing Us!</h3> : <><input onChange={handlechange} name="email" type="text" className="form-control footer_input" placeholder="Enter your e-mail address" aria-label="Recipient's username" aria-describedby="button-addon2" />
                <div className="input-group-append">
                  <button onClick={submit_cust} className="btn btn-success footer_button" type="button" id="button-addon2" style={{ height: "100%" }}> <b>Join</b></button>
                </div></>:<h3>Thanks For subscribing us!</h3>}
            </div>
            <ul className="list-unstyled">
              <li>
                <p className="mb-0 pb-0 mt-5">FOLLOW THE WEB ON SOCIAL NETWORKS</p>
              </li>
              <li>
                <a className="fa "><FontAwesomeIcon icon={faFacebook} /></a>
                <a className="fa "><FontAwesomeIcon icon={faTwitter} /></a>
                <a className="fa "><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="fa"><FontAwesomeIcon icon={faLinkedin} /></a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="line" />
        <div className="row justify-content-lg-around  mx-xl-5 mx-lg-4 mx-3 py-3">
          <div className="col order-1 align-self-center">
            <p className="mb-0 text-uppercase footer_p">we're accountable for seven days a week, 24 hours a day</p><small>Contact Number : 0124-4055078 <br /> Email Address: arungarg@ranilgroup.com </small>
          </div>
          <div className="ranil_desc">
            <p className='footer_p'><span style={{ fontSize: "20px" }}>R</span>anilGroup is one of the most reputed and trusted group located in Gurgaon, India. The work of Ranil Group is not confined in a box. The prime business of Ranil group is real estate. It works in various verticals like construction, Education, Hospitality etc.</p>
          </div>
        </div>
        <hr className="line" />
        <div className="row text-center py-3">
          <div className="col">
            <p className="text-muted">
              <b>All rights are reserved by RanilGroup Pvt. Limited <FontAwesomeIcon icon={faCopyright} /></b>
            </p>
          </div>
        </div>
      </footer>

      {/* Modal for terms and conditions */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                Ranil Groups - Terms and Conditions</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <div className='row'>
                  <p><b>Acceptance of Terms:</b>By accessing and using the services provided by Ranil Groups, you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>
                  <p><b>Privacy and Data:</b>Ranil Groups is committed to safeguarding your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal data. By using our services, you consent to our data practices.</p>
                  <p><b>Service Description:</b>Ranil Groups offers a range of services related to real estate, land investment, property development, and more. The specific details of each service may be found on our website or communicated directly to clients.</p>
                  <p><b>Intellectual Property:</b> All content, logos, trademarks, and materials presented on the Ranil Groups website are protected by intellectual property rights and may not be used without explicit permission.</p>
                  <p><b>User Conduct:</b>Users are expected to use our services responsibly and in compliance with all applicable laws and regulations. Inappropriate or harmful behavior, including spam or abuse, is strictly prohibited.</p>
                  <p><b>Financial Information:</b> Any financial information provided to Ranil Groups is handled with the utmost security and privacy. Ranil Groups is not responsible for the actions of third-party payment processors.</p>
                  <p><b>Accuracy of Information:</b>While we make every effort to ensure the accuracy of the information provided on our website, Ranil Groups is not liable for any inaccuracies or errors. It is advised to verify all information independently.</p>
                  <p><b>Modification of Terms: </b>Ranil Groups reserves the right to modify or update these terms and conditions at any time. Users are encouraged to review this page periodically for changes.</p>
                  <p><b>Governing Law:</b>These terms and conditions are governed by the laws of the relevant jurisdiction in which Ranil Groups operates.</p>
                  <p><b>Contact Information:</b>If you have any questions or concerns about these terms and conditions, please contact us at support@ranilgroup.com .</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="custom-gradient-button" style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Accept</button>
            </div>
          </div>
        </div>
      </div >

      {/* Modal for privacy policy  */}
      <div className="modal fade" id="privacymodal" tabIndex="-1" aria-labelledby="privacymodalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
                Privacy Policy for Ranil Group</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <div className='row'>
                  <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit ranilgroup.com or engage with Ranil Group's services.</p>

                  <strong>Personal Information We Collect</strong>

                  <p>
                    When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or services that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site.
                  </p>

                  <p>
                    We collect personal information such as your name, email address, phone number, and company name when you voluntarily submit inquiries, contact us, or request information.
                  </p>

                  <strong>How We Use Your Personal Information</strong>

                  <p>
                    We use the personal information we collect to:<br />
                    - Provide and maintain our services. <br />
                    - Respond to your requests, comments, or questions. <br />
                    - Communicate with you, including sending emails to confirm your inquiries or provide updates and information. <br />
                    - Screen our inquiries for potential risk or fraud. <br />
                    - Provide you with information, products, or services you request or that may be of interest to you. <br />
                  </p>

                  <strong>Sharing Your Personal Information</strong>

                  <p>We share your personal information with third parties to help us use your personal information, as described above. For example, we use Google Analytics to help us understand how our visitors use the Site. We also use a contact form provider to manage inquiries submitted through the website. Your personal information is never shared with advertisers.

                  </p>

                  <strong>Data Retention</strong>

                  <p>When you submit an inquiry through the Site, we will maintain your information for our records unless and until you request its deletion.</p>

                  <strong>Changes</strong>

                  <p>We may update this privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.</p>

                  <strong>Contact Us</strong>

                  <p>For more information about our privacy practices, or if you have questions or concerns, please contact us at arungarg@ranilgroup.com</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="custom-gradient-button" style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Accept</button>
            </div>
          </div>
        </div>
      </div >

      {/* modal for cookie policy  */}
      <div className="modal fade" id="cookiemodal" tabIndex="-1" aria-labelledby="cookiemodalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-center" id="exampleModalLabel" style={{ fontFamily: "Noto Serif JP, serif" }}>
              Cookie Policy for Ranil Group</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <div className='row'>
                  <strong>What Are Cookies</strong>
                  <p>As is common practice with almost all professional websites, this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it, and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored; however, this may downgrade or 'break' certain elements of the site's functionality.</p>

                  <p>For more general information on cookies, please read ["What Are Cookies"](https://www.cookiesandyou.com/).</p>

                  <strong>How We Use Cookies</strong>

                  <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave all cookies if you are not sure whether you need them or not, in case they are used to provide a service that you use.</p>

                  <strong>Disabling Cookies</strong>

                  <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser's Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies.</p>

                  <strong>The Cookies We Set</strong>

                  <p>- <strong>Site preferences cookies:</strong> In order to provide you with a great experience on this site, we provide the functionality to set your preferences for how this site runs when you use it. To remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page affected by your preferences.</p>

                  <strong>More Information</strong>

                  <p>Hopefully, that has clarified things for you, and as previously mentioned, if there is something that you aren't sure whether you need or not, it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. However, if you are still looking for more information, you can contact us through one of our preferred contact methods.</p>

                  <strong>Email: admin@ranilgroup.com</strong>
                  </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="custom-gradient-button" style={{ padding: "7px 17px", width: "30%", border: "1px solid black" }}>Accept</button>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
