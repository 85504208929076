import React, {useEffect, useState} from 'react'
import CircularProgressBar from './CircularProgressBar'

const Success = ({progress}) => {
  return (
    <div className='container sticky-top'>
      <div className='row my-3'>
        <h5>Why we require detail adress?</h5>
        <p>
            Location is the most important for buyer's. By capturing a detailed location we ensure you get a genuine leads.
        </p>
      </div>
      <div className='row my-2'>
        <h5> Your Property Score </h5>
        <CircularProgressBar progress={progress}/>
      </div>
    </div>
  )
}

export default Success
